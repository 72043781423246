import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MESSAGE } from '../../utils/message';
import SimplePopup from './SimplePopup';

const SignupPopup = () => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/signIn');
  };
  return (
    <SimplePopup
      title={MESSAGE.PRE_USER.TITLE}
      content={MESSAGE.PRE_USER.CONTENT}
      confirm={MESSAGE.PRE_USER.BUTTON}
      onClick={onClick}
    />
  );
};

export default SignupPopup;
