import React, { createContext, useContext, useMemo, useState } from 'react';

export const PopupContext = createContext(null);

export const PopupProvider = ({ children }) => {
  const [isShowing, setIsShowing] = useState(false);
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  const [state, setState] = useState(null);

  const openPopup = ({ popup, content = '', id = null }) => {
    setIsShowing(true);
    setType(() => popup);
    setMessage(content);
    setState(id);
  };

  const closePopup = () => {
    setIsShowing(false);
    setType('');
  };

  const store = useMemo(
    () => ({
      isShowing,
      openPopup,
      closePopup,
      type,
      message,
      state,
    }),
    [isShowing, message, state, type]
  );

  return (
    <PopupContext.Provider value={store}>{children}</PopupContext.Provider>
  );
};

export const usePopupContext = () => {
  const store = useContext(PopupContext);
  if (store) return store;
};
