import React from 'react';
import { useNavigate } from 'react-router-dom';
import SimplePopup from './SimplePopup';

const SignUpErrorPopup = ({ state }) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/signIn');
  };
  return (
    <SimplePopup
      title={state.title}
      content={state.content}
      onClick={onClick}
    />
  );
};

export default SignUpErrorPopup;
