import React from 'react';
import useDelParty from '../../Party/hooks/useDelParty';
import { MESSAGE } from '../../utils/message';
import SimplePopup from './SimplePopup';

const PartyDeletePopup = ({ state }) => {
  const deleteMutation = useDelParty();
  const onClick = () => {
    deleteMutation.mutate({ id: state.id });
  };
  return (
    <SimplePopup
      title={MESSAGE.PARTT_DELETE_POPUP.TITLE}
      content={MESSAGE.PARTT_DELETE_POPUP.CONTENT}
      confirm={MESSAGE.PARTT_DELETE_POPUP.BUTTON}
      cancel="닫기"
      color="#ED4956"
      onClick={onClick}
    />
  );
};

export default PartyDeletePopup;
