import React from 'react';
import { IMG } from '../../utils/image';
import AppMovePopup from './AppMovePopup';

const AppMoveDutchPopup = () => {
  return (
    <AppMovePopup
      title={`앱을 통해 친구와 함께\n간편하게 1/N로 결제해보세요!`}
      notice="1/N 결제 기능이 궁금하신가요?"
      confrim="앱 이용하기"
      disagree="지금은 사용하지 않을래요."
      link="/event/dutch_pay.html"
      src={IMG.DUTCH_APP.url}
    />
  );
};

export default AppMoveDutchPopup;
