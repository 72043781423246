import React from 'react';
import usePartyStatus from '../../Party/hooks/usePartyStatus';
import { MESSAGE } from '../../utils/message';
import SimplePopup from './SimplePopup';

const PartyCancelOpenPopup = ({ state }) => {
  const partyStatusMutation = usePartyStatus({
    key: ['activity', 'party', 'host'],
    content: `파티 오픈이 취소되었어요.`,
  });

  const onClick = () => {
    partyStatusMutation.mutate({ id: state.id, action: 'cancel' });
  };
  return (
    <SimplePopup
      title={MESSAGE.PARTT_CANCEL_OPEN_POPUP.TITLE}
      content={MESSAGE.PARTT_CANCEL_OPEN_POPUP.CONTENT}
      confirm={MESSAGE.PARTT_CANCEL_OPEN_POPUP.BUTTON}
      cancel="닫기"
      color="#ED4956"
      onClick={onClick}
    />
  );
};

export default PartyCancelOpenPopup;
