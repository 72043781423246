import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { getFiltersOption } from 'utils/utils';

const { persistAtom } = recoilPersist();

export const snackbarState = atom({
  key: 'snackbarState',
  default: {
    open: false,
    content: '',
  },
});

export const signupState = atom({
  key: 'signupState',
  default: {
    provider: '',
    sex: '',
    name: '',
    birthday: '',
    phone_number: '',
    provider_access_token: '',
    provider_id_token: '',
    code: '',
    nick_name: '',
    agree_mkt_push: false,
    agree_mkt_email: false,
    agree_mkt_sms: false,
  },
  effects_UNSTABLE: [persistAtom],
});

export const bottomSheetState = atom({
  key: 'bottomSheetState',
  default: {
    type: '',
    open: false,
    state: {},
  },
});

export const popupState = atom({
  key: 'popupState',
  default: {
    type: '',
    open: false,
    state: {},
  },
});

export const fullScreenState = atom({
  key: 'fullscreenState',
  default: {
    type: '',
    open: false,
    state: {},
  },
});

export const bookingRefundTitle = atom({
  key: 'bookingRefundTitle',
  default: {
    title: '',
    until: '',
    color: '',
  },
  effects_UNSTABLE: [persistAtom],
});

export const loaderState = atom({
  key: 'loaderState',
  default: {
    open: false,
  },
});

export const withdrawReason = atom({
  key: 'withdrawReason',
  default: {
    id: '',
    text: '',
  },
});

export const historyState = atom({
  key: 'historyState',
  default: {
    route: '',
  },
  effects_UNSTABLE: [persistAtom],
});

export const filterState = atom({
  key: 'filterState',
  default: {
    ...getFiltersOption(),
    region: '전국',
    area_code: '',
    sorting: '추천순',
    f_category: ['all'],
  },
});

export const recommentState = atom({
  key: 'recommentState',
  default: {
    open: false,
    state: {},
  },
});

export const videoState = atom({
  key: 'videoState',
  default: {
    playing: true,
    muted: true,
    played: 0,
    buffer: false,
  },
});

export const inPropState = atom({
  key: 'inPropState',
  default: {
    open: false,
  },
});

export const paymentState = atom({
  key: 'paymentState',
  default: {
    selectedCouponId: null,
    couponDiscountAmount: 0,
    point: 0,
    usedPoint: 0,
  },
});

export const annoymousState = atom({
  key: 'annoymousState',
  default: {
    bookingNumber: '',
    phone: '',
  },
});

export const expectPointState = atom({
  key: 'expectPointState',
  default: {
    expectPoint: 0,
    classifications: [],
  },
  effects_UNSTABLE: [persistAtom],
});

export const calcExpectPointPercent = selector({
  key: 'calcExpectPoint',
  get: ({ get }) => {
    const { classifications } = get(expectPointState);
    return classifications.filter(
      (item) => item === 'hotel' || item === 'motel'
    ).length > 0
      ? 0.03
      : 0.05;
  },
});
