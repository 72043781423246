import styled, { css, keyframes } from 'styled-components';

export const slideUp = keyframes`
0% {
  transform: translate3d(0, 100%, 0);
}
100% {
  transform: translate3d(0, 0, 0);
}
`;

export const slideDown = keyframes`
0% {
  transform: translate3d(0, 0, 0);
}
100% {
  transform: translate3d(0, 100%, 0);
}
`;

export const overlay = keyframes`
0% {
  visibility: visible;
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

export const overlayOff = keyframes`
0% {
  opacity: 1;
}
100% {
  opacity: 0;
  visibility: hidden;
}
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  width: 500px;
  max-width: 100%;
  height: calc((var(--vh, 1vh) * 100));
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 101;
  transform: translateX(-50%);
  ${(props) =>
    props.isShowing
      ? css`
          animation: ${overlay} 0.2s 0s forwards;
        `
      : css`
          animation: ${overlayOff} 0.2s 0.5s forwards;
        `};
`;

export const ContainerButtonSheet = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ButtonSheetLi = styled.button`
  cursor: pointer;

  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: ${(props) =>
    props.hilight ? props.hilight : 'var(--theme-text-primary)'};
  font-size: var(--theme-text-m);
  border-bottom: 1px solid #f1f1f1;

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: none;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 125px;
  background: #f8f8f8;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 14px;
  font-size: var(--theme-text-m);
  line-height: 1.5rem;
  color: var(--theme-text-primary);
`;
