import React from 'react';
import { fullScreenState } from 'atoms/atoms';
import { useRecoilValue } from 'recoil';
import SimpleFullscreen from './SimpleFullscreen';
import { FULLSCREEN } from 'constants/popup';
import PropertyMapModal from './PropertyMapModal';
import PropertyImgsFullscreen from './PropertyImgsFullscreen';
import CouponSelectorModal from './CouponSelectorModal';

const FullScreen = () => {
  const fullscreen = useRecoilValue(fullScreenState);

  if (fullscreen.type === FULLSCREEN.SIMPLE) return <SimpleFullscreen />;
  if (fullscreen.type === FULLSCREEN.PROPERTY_MAP) return <PropertyMapModal />;
  if (fullscreen.type === FULLSCREEN.PROPERTY_IMG)
    return <PropertyImgsFullscreen />;
  if (fullscreen.type === FULLSCREEN.COUPON_SELECTOR)
    return <CouponSelectorModal />;
  return null;
};

export default FullScreen;
