import { useCallback } from 'react';
import { fullScreenState } from 'atoms/atoms';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

const useFullScreenClose = () => {
  const navigate = useNavigate();
  const [fullscreen, setFullscreen] = useRecoilState(fullScreenState);
  const resetFullscreen = useResetRecoilState(fullScreenState);

  const onClickClose = useCallback(({ nav = true }) => {
    setFullscreen(() => ({ ...fullscreen, open: false }));
    setTimeout(() => {
      resetFullscreen();
    }, 500);
    nav && navigate(-1, { replace: true });
  }, []);

  return { onClickClose };
};

export default useFullScreenClose;
