import React from 'react';
import styled from 'styled-components';
import Loader from './Loader';
import ReactPortal from './portal/ReactPortal';

const BackgroundLoader = () => {
  return (
    <ReactPortal wrapperId="react-portal-loader">
      <Background>
        <Container>
          <Loader />
        </Container>
      </Background>
    </ReactPortal>
  );
};

const Background = styled.div`
  background: #ffffff;
`;

const Container = styled.section`
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  z-index: 101;
  transform: translateX(-50%);
  visibility: visible;
  /* ${(props) => (props.isShowing ? 'visible' : 'hidden')}; */
`;

export default BackgroundLoader;
