import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';
import { IMG } from 'utils/image';

const LazyImg = ({
  src,
  alt,
  width,
  height,
  ratio,
  object = 'cover',
  lazy = true,
  banner = false,
  multi = false,
  styles,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });

  const onError = (e) => {
    e.target.src = IMG.NO_IMG.url;
  };
  if (!lazy)
    return (
      <Img
        src={src}
        alt={alt}
        width={width}
        height={height}
        ratio={ratio}
        object={object}
        onError={onError}
        banner={banner}
        multi={multi}
        styles={styles}
      />
    );
  return (
    <Img
      ref={ref}
      src={inView ? src : IMG.NO_IMG.url}
      data-src={src}
      alt={alt}
      width={width}
      height={height}
      ratio={ratio}
      object={object}
      loading="lazy"
      onError={onError}
      banner={banner}
      multi={multi}
      styles={styles}
    />
  );
};

const Img = styled.img`
  aspect-ratio: ${(props) => props.ratio};
  object-fit: ${(props) => props.object};
  ${(props) =>
    props.banner &&
    css`
      width: 100%;
      height: auto;
    `};
  ${(props) =>
    props.multi &&
    css`
      border-radius: 20px 20px 0 20px;
    `}
  ${(props) => props.styles && props.styles};
`;

export default React.memo(LazyImg);
