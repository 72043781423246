import _ from 'lodash';

export const randomString = (len = 12) => {
  const arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, (dec) => dec.toString(16).padStart(2, '0')).join('');
};

export const abbr = (n, locale = 'ko-KR') => {
  return Intl.NumberFormat(locale, {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(n);
};

export const nf = (n) => {
  return _.toNumber(n).toLocaleString();
};

export const setComma = (str) => {
  return String(str).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
};

export const removeComma = (str) => {
  return String(str).replace(/[^\d]+/g, '');
};

export const nl2br = (string) => {
  return string.replaceAll('\n', '<br />');
};
