import {
  format,
  add,
  addDays,
  getDay,
  getYear,
  getMonth,
  differenceInMilliseconds,
  differenceInHours,
  parseISO,
  differenceInDays,
} from 'date-fns';
import { ko } from 'date-fns/locale';
import { nf } from '../functions';

export const setTitle = (categories) => {
  const transCategory = (category) => {
    switch (category) {
      case 'premium':
        return '프리미엄';
      case 'motel':
        return '모텔';
      case 'hotel':
        return '호텔';
      case 'pension':
        return '펜션';
      case 'poolvilla':
        return '풀빌라';
      case 'resort':
        return '리조트';
      case 'camping':
        return '캠핑';
      case 'glamping':
        return '글램핑';
      case 'caravan':
        return '카라반';
      case 'guesthouse':
        return '게스트하우스';
      case 'hanok':
        return '한옥';
      default:
        return;
    }
  };
  return categories.map((category) => transCategory(category)).join(' / ');
};

export const setCategory = (category) => {
  switch (category) {
    case 'all':
      return '전체';
    case 'motel':
      return '모텔';
    case 'hotel':
      return '호텔';
    case 'pension/poolvilla':
      return '펜션/풀빌라';
    case 'resort':
      return ['resort'];
    case 'camping/glamping/caravan':
      return '캠핑/글램핑';
    case 'guesthouse/hanok':
      return '게하/한옥';
    default:
      return '';
  }
};

export const applyForISO = (date) => {
  return new Date(date.replace(' ', 'T'));
};

export const getToday = () => {
  return format(new Date(), 'yyyy-MM-dd');
};

export const getNextDate = (date) => {
  return format(add(new Date(date), { days: 1 }), 'yyyy-MM-dd');
};

export const getDefaultFilter = () => {
  return {
    from_date: getToday(),
    to_date: getNextDate(getToday()),
    capacity: 2,
  };
};

export const getPeriodDate = () => {
  const today = new Date();
  const todayFormat = format(today, 'yyyy-MM-dd');
  const nextDay = addDays(today, 1);
  const nextDayFormat = format(nextDay, 'yyyy-MM-dd');

  return {
    from: today,
    formattedFrom: todayFormat,
    to: nextDay,
    formattedTo: nextDayFormat,
  };
};

export const transformDayInKo = (day) => {
  switch (day) {
    case 0:
      return '일';
    case 1:
      return '월';
    case 2:
      return '화';
    case 3:
      return '수';
    case 4:
      return '목';
    case 5:
      return '금';
    case 6:
      return '토';
    default:
      return '';
  }
};

export const boldText = (text, color) => {
  return text.replace(
    /\[(.*?)\]/g,
    `<span style="font-weight: bold; color: ${color}">[$1]</span>`
  );
};

export const getKoDate = (cur) => {
  const date = new Date(cur);
  const year = getYear(date);
  const month = getMonth(date);
  const day = getMonth(date);
  return `${year}년 ${month}월 ${day}일`;
};

export const getPeriodSelector = (date) => {
  const mmyy = format(date, 'MM/dd');
  const day = transformDayInKo(getDay(date));
  return `${mmyy}(${day})`;
};

export const sliceInsta = (id) => {
  return id.split('https://www.instagram.com/')[1];
};

export const sliceLink = (id) => {
  return id.split('https://')[1].split('.com')[0];
};

export const transCategory = (category) => {
  return category === 'announce' ? '공지' : '안내';
};

export const setHeightForMobile = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
};

export const getGeolocation = () => {
  window.navigator.geolocation.getCurrentPosition((pos) => {
    if (window.naver.maps) {
      window.naver.maps.Service.reverseGeocode(
        {
          location: new window.naver.maps.LatLng(
            pos.coords.latitude,
            pos.coords.longitude
          ),
        },
        (status, response) => {
          const result = response.result.items[0].addrdetail;
          sessionStorage.setItem(
            'userGeolocation',
            JSON.stringify({
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude,
              position: `${result.sido} ${result.sigugun} ${result.dongmyun}`,
            })
          );
          window.location.reload();
        }
      );
    }
  });
};

export const setGeolocation = (position) => {
  window.sessionStorage.setItem(
    'userGeolocation',
    JSON.stringify({ ...position })
  );
};

export const setDefaultFilters = () => {
  return {
    capacity: 2,
    from_date: new Date(),
    to_date: new Date(addDays(new Date(), 1)),
  };
};

export const getAmount = (min, max) => {
  if (min && max)
    return `최소 사용금액 ${nf(min)}원 / 최대 할인금액 ${nf(max)}`;
  else if (min && !max) return `최소 사용금액 ${nf(min)}원`;
  else if (!min && max) return `최대 할인금액 ${nf(max)}`;
  return null;
};

export const dateFormatUntilHours = (date) => {
  return format(parseISO(date), 'yyyy년 MM월 dd일 HH시', { locale: ko });
};
export const dateFormatUntilDay = (date) => {
  return `${format(parseISO(date), 'MM.dd (E)', { locale: ko })}`;
};

export const dateFormatForTime = (date) => {
  return format(parseISO(date), 'HH : mm', { locale: ko });
};

export const isCheckin = (checkin) => {
  return differenceInMilliseconds(parseISO(checkin), new Date()) <= 0;
};

export const remindTime = (checkin) => {
  const YEAR = 8640;
  const MONTH = 720;
  const WEEK = 168;
  const DAY = 24;
  const difference = Math.abs(
    differenceInHours(applyForISO(checkin), new Date())
  );
  if (difference >= YEAR) {
    return `${parseInt(difference / YEAR, 10)}년`;
  } else if (difference >= MONTH) {
    return `${parseInt(difference / MONTH, 10)}개월`;
  } else if (difference >= WEEK) {
    return `${parseInt(difference / WEEK, 10)}주`;
  } else if (difference >= DAY) {
    return `${parseInt(difference / DAY, 10)}일`;
  } else {
    return `${difference}시간`;
  }
};

//localstorage 관련

export const setLocalStorageExpiration = ({ key, value, ttl }) => {
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + ttl,
  };
  window.localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageItem = ({ key }) => {
  const itemStr = window.localStorage.getItem(key);
  if (!itemStr) return null;

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const setFiltersOption = (options) => {
  window.localStorage.setItem('filters', JSON.stringify({ ...options }));
};

export const getFiltersOption = () => {
  const filter = JSON.parse(window.localStorage.getItem('filters'));
  if (!filter) return setDefaultFilters();
  return {
    ...filter,
    from_date: new Date(filter.from_date),
    to_date: new Date(filter.to_date),
  };
};

export const getUrlSearchParams = (url) => {
  const urlSearch = new URL(url).searchParams;
  return { page: urlSearch.get('page'), id: urlSearch.get('id') };
};

export const urlSearchParams = (url, key) => {
  const urlSearch = new URL(url).searchParams;
  return urlSearch.get(key);
};

export const checkFilters = (cur, today) => {
  return differenceInDays(new Date(cur), today) < 0;
};
