import { BasePrice } from 'property/room/components/ButtomBtn';
import styled from 'styled-components';
import { Col, Row } from 'styles/Layout';

export const Container = styled.section`
  margin-top: 50px;
`;

export const ColContainer = styled(Col)`
  padding: 30px 20px;
`;

export const ColorContainer = styled(Col)`
  padding: 30px 20px;
  background: ${(props) => (props.color ? props.color : 'transparent')};
`;

export const Title18 = styled.span`
  font-size: var(--theme-text-xl);
  color: ${(props) =>
    props.color ? props.color : 'var(--theme-text-primary)'};
  font-weight: bold;
  em {
    color: var(--theme-blue-primary);
  }
`;

export const Text24 = styled.span`
  font-size: 1.5rem;
  color: ${(props) =>
    props.color ? props.color : 'var(--theme-text-primary)'};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`;
export const Text14 = styled.span`
  font-size: var(--theme-text-m);
  color: ${(props) =>
    props.color ? props.color : 'var(--theme-text-primary)'};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`;

export const Text12 = styled.span`
  font-size: var(--theme-text-xs);
  color: ${(props) =>
    props.color ? props.color : 'var(--theme-text-primary)'};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`;
export const Text = styled.span`
  font-size: ${(props) => (props.font ? props.font : 'var(--theme-text-l)')};
  color: ${(props) =>
    props.color ? props.color : 'var(--theme-text-primary)'};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`;

export const Text10 = styled.span`
  font-size: var(--theme-text-xxxs);
  color: ${(props) =>
    props.color ? props.color : 'var(--theme-text-primary)'};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`;

export const TextRefund = styled.span`
  font-size: var(--theme-text-xs);
  color: ${(props) =>
    props.color === 'red'
      ? 'var(--theme-red-primary)'
      : 'var(--theme-blue-primary)'};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`;

export const SubText = styled.span`
  font-size: var(--theme-text-s);
  color: var(--theme-text-secondary);
`;

export const Boundary = styled.div`
  width: 100%;
  height: 10px;
  background: #f1f1f1;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const UserInput = styled.input`
  padding: 0 0 14px;
  border-bottom: 1px solid #d2d2d2;
`;

export const PointLabel = styled.label`
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  margin-bottom: 14px;
  text-align: right;
  display: flex;
  gap: 4px;
  padding: 6px;
  span {
    font-size: 14px;
    color: var(--theme-text-secondary);
  }
`;

export const PointInput = styled.input`
  width: 100%;
  text-align: right;
  font-size: 14px;
  color: var(--theme-text-secondary);
`;

export const PointButton = styled.button`
  background: transparent;
  border-radius: 7px 7px 0px 7px;
  border: 1px solid #909090;
  padding: 4px 12px;
  white-space: nowrap;
`;

export const UsablePoint = styled.span`
  font-size: 9px;
  color: var(--theme-text-secondary);
  white-space: nowrap;
`;

export const ColCenter = styled(Col)`
  align-items: center;
`;

export const PaymentLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const CheckMethod = styled.input.attrs({
  type: 'radio',
})``;

export const Method = styled.span`
  font-size: var(--theme-text-m);
  line-height: 17px;
  color: var(--theme-text-primary);
`;

export const PaymentBtn = styled.button`
  width: 100%;
  border-radius: 10px 10px 0px 10px;
  color: #ffffff;
  font-size: var(--theme-text-l);
  font-weight: bold;
  padding: 14px;
  background: var(--theme-blue-primary);
  :disabled {
    background: var(--theme-button-disabled);
  }
`;

export const RowPosition = styled(Row)`
  position: relative;
`;

export const BasePriceForPayment = styled(BasePrice)`
  position: absolute;
  top: -20px;
  right: 0;
`;
