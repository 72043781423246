import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MESSAGE } from '../../utils/message';
import SimplePopup from './SimplePopup';

const CompleteWithdrawPopup = ({ state }) => {
  const navigate = useNavigate();
  return (
    <SimplePopup
      title={MESSAGE.COMPLETE_WITHDRAW.TITLE}
      content={`${state.id}${MESSAGE.COMPLETE_WITHDRAW.CONTENT}`}
      confirm={MESSAGE.COMPLETE_WITHDRAW.BUTTON}
      onClick={() => navigate('/', { replace: true })}
    />
  );
};

export default CompleteWithdrawPopup;
