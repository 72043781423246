import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from 'API';
import { snackbarState } from 'atoms/atoms';
import { useSetRecoilState } from 'recoil';

const useIssue = () => {
  const openSnackbar = useSetRecoilState(snackbarState);
  const queryClient = useQueryClient();
  const fetchApi = async ({ code }) => {
    await API.put('coupon/me', {
      coupon_code: `${code}`,
    });
  };
  return useMutation(fetchApi, {
    onSuccess: () => {
      openSnackbar({ open: true, content: '쿠폰이 발급되었습니다.☺️' });
      queryClient.invalidateQueries(['coupon', 'useable']);
    },
    onError: (e) => {
      openSnackbar({ open: true, content: e.response.data.error.message });
    },
  });
};

export default useIssue;
