import React from 'react';

import ChannelImg from 'resources/img/icon/icon-channel.svg';
import styled from 'styled-components';
import { checkWebview } from 'utils/webview';
const ChannelButton = ({ bottom }) => {
  if (!checkWebview())
    return (
      <Container className="custom-button-1" bottom={bottom}>
        <img src={ChannelImg} alt="채널톡" width="50" height="50" />
      </Container>
    );
  return <></>;
};

const Container = styled.button`
  position: fixed;
  display: block;
  flex-wrap: nowrap;
  width: auto;
  overflow: visible;
  background: none;
  bottom: ${(props) => props.bottom}px;
  right: 14px;
  z-index: 1000;
  transition: all 0.2s;
  button {
    background: none;
  }
  @media screen and (min-width: 640px) {
    bottom: 14px;
  }
`;

export default ChannelButton;
