import React from 'react';
import { useRecoilValue } from 'recoil';
import { popupState } from '../../atoms/atoms';
import { POPUP_TYPE } from '../../constants/popup';
import { MESSAGE } from '../../utils/message';
import AppMoveDutchPopup from './AppMoveDutchPopup';
import BasicPopup from './BasicPopup';
import CompleteWithdrawPopup from './CompleteWithdrawPopup';
import FirstVisitPopup from './FirstVisitPopup';
import FollowerPopup from './FollowerPopup';
import LoginPopup from './LoginPopup';
import LogoutPopup from './LogoutPopup';
import MomentPopup from './MomentPopup';
import PartyAgreePopup from './PartyAgreePopup';
import PartyCancelJoinPopup from './PartyCancelJoinPopup';
import PartyCancelOpenPopup from './PartyCancelOpenPopup';
import PartyCancelWaitingPopup from './PartyCancelWaitingPopup';
import PartyDeletePopup from './PartyDeletePopup';
import PartyRejectJoinPopup from './PartyRejectJoinPopup';
import PartyRejectPopup from './PartyRejectPopup';
import PropertyBookingPopup from './PropertyBookingPopup';
import ReservationDeletePopup from './ReservationDeletePopup';
import ReservationTooltipPopup from './ReservationTooltipPopup';
import ReservationWithdrawPopup from './ReservationWithdrawPopup';
import ReservationWithdrawReasonPopup from './ReservationWithdrawReasonPopup';
import SignUpErrorPopup from './SignUpErrorPopup';
import SignupPopup from './SignupPopup';
import SimplePopup from './SimplePopup';
import WithdrawPopup from './WithdrawPopup';
import CheckReservation from './CheckReservation';
import LoginWithNoMember from './LoginWithNoMember';

const Popup = () => {
  const { type, state } = useRecoilValue(popupState);

  if (type === POPUP_TYPE.LOGIN) return <LoginPopup />;
  if (type === POPUP_TYPE.LOGOUT) return <LogoutPopup />;
  if (type === POPUP_TYPE.WITHDRAW) return <WithdrawPopup state={state} />;
  if (type === POPUP_TYPE.COMPLETE_WITHDRAW)
    return <CompleteWithdrawPopup state={state} />;
  if (type === POPUP_TYPE.POINT)
    return (
      <SimplePopup
        title={MESSAGE.POINT.TITLE}
        content={MESSAGE.POINT.CONTENT}
        confirm="확인"
      />
    );
  if (type === POPUP_TYPE.PRE_AUTH) return <SignupPopup />;
  if (type === POPUP_TYPE.FOLLOWER) return <FollowerPopup state={state} />;
  if (type === POPUP_TYPE.MOMENT) return <MomentPopup />;
  if (type === POPUP_TYPE.PARTY_CANCEL_WAITING)
    return <PartyCancelWaitingPopup state={state} />;
  if (type === POPUP_TYPE.PARTY_CANCEL_JOIN)
    return <PartyCancelJoinPopup state={state} />;
  if (type === POPUP_TYPE.PARTY_DELETE)
    return <PartyDeletePopup state={state} />;
  if (type === POPUP_TYPE.PARTY_CANCEL_OPEN)
    return <PartyCancelOpenPopup state={state} />;
  if (type === POPUP_TYPE.PARTY_AGREE) return <PartyAgreePopup state={state} />;
  if (type === POPUP_TYPE.PARTY_REJECT)
    return <PartyRejectPopup state={state} />;
  if (type === POPUP_TYPE.PARTY_REJECT_JOIN)
    return <PartyRejectJoinPopup state={state} />;
  if (type === POPUP_TYPE.TOOLTIP_RESERVATION_DETAIL)
    return <ReservationTooltipPopup state={state} />;
  if (type === POPUP_TYPE.SIGNUP_ERROR)
    return <SignUpErrorPopup state={state} />;
  if (type === POPUP_TYPE.RESERVATION_WITHDRAW)
    return <ReservationWithdrawPopup state={state} />;
  if (type === POPUP_TYPE.RESERVATION_WITHDRAW_REASON)
    return <ReservationWithdrawReasonPopup />;
  if (type === POPUP_TYPE.RESERVATION_DELETE)
    return <ReservationDeletePopup state={state} />;
  if (type === POPUP_TYPE.BASIC) return <BasicPopup state={state} />;
  if (type === POPUP_TYPE.FIRST_VISIT) return <FirstVisitPopup />;
  if (type === POPUP_TYPE.DUTCH_FOR_MOBILE) return <AppMoveDutchPopup />;
  if (type === POPUP_TYPE.PROPERTY_BOOKING)
    return <PropertyBookingPopup state={state} />;
  if (type === POPUP_TYPE.CHECK_RESERVATION)
    return <CheckReservation state={state} />;
  if (type === POPUP_TYPE.RESERVATION_NO_MEMBER)
    return <LoginWithNoMember state={state} />;
  return null;
};

export default Popup;
