import styled from 'styled-components';

export const Between = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.gap && `gap: ${props.gap}px`};
`;

export const Row = styled.div`
  display: flex;
  align-items: ${(props) => (props.align ? props.align : 'center')};
  ${(props) => props.gap && `gap: ${props.gap}px`};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => props.gap && `gap: ${props.gap}px`};
`;

export const Col20 = styled(Col)`
  gap: 20px;
`;
