import API from '../../API';

export const getAccount = async () => {
  const { data } = await API.get('account/me');
  return data;
};

export const getKakaoAcount = async (propviderAccessToken) => {
  const data = await fetch(`https://kapi.kakao.com/v2/user/service_terms`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${propviderAccessToken}`,
      'Content-type': `application/x-www-form-urlencoded;charset=utf-8`,
    },
  });
  return data.json();
};
