import { createGlobalStyle } from 'styled-components';
import { COLOR } from '../constants/colors';

export const theme = {
  COLOR,
};

export const GlobalStyle = createGlobalStyle`
  :root {
    --theme-blue-primary: #0080FF;
    --theme-blue-secondary: #00B2FF;
    --theme-blue-teritary: rgb(0, 128, 255, 0.3);
    --theme-red-primary: #ED4956;
    --theme-red-secondary: #FF007A;
    --theme-red-teritary: rgb(255, 0, 122, 0.3);
    --theme-yellow-primary: #FFF626;
    --theme-yellow-secondary: #FFFF8D;
    --theme-text-primary: #463E3F;
    --theme-text-secondary: #909090;
    --theme-text-teritary: #C4C4C4;
    --theme-button-active: #0080FF;
    --theme-button-disabled: #F1F1F1;
    --theme-button-canceled: #D2D2D2;
    --theme-button-cancel-reservation: #FDEDEE;
    --theme-button-one-stroke: #D2D2D2;
    --theme-text-xl: 1.125rem;
    --theme-text-l: 1rem;
    --theme-text-m: 0.875rem;
    --theme-text-s: 0.813rem;
    --theme-text-xs: 0.75rem;
    --theme-text-xxs: 0.688rem;
    --theme-text-xxxs: 0.625rem;
    --theme-conetnt-gap: 1.875rem;
    --theme-spacer-height: 0.625rem;
    --theme-content-padding-horizen: 1.25rem;
  }
`;
