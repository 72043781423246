import React from 'react';
import SimplePopup from './SimplePopup';

const CheckReservation = () => {
  return (
    <SimplePopup
      title="앗, 조회에 실패했어요 😣"
      content={
        <p>
          유효하지 않은 예약번호예요.<p> 다시 한 번 확인 부탁드려요.</p>
        </p>
      }
    />
  );
};

export default CheckReservation;
