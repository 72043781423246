import React from 'react';
import styled from 'styled-components';

const EmptyMessage = ({ title, subTitle, button, onClick }) => {
  return (
    <Empty>
      <Title>{title} </Title>
      <SubTitle>{subTitle}</SubTitle>
      {button && <Btn onClick={onClick}>{button}</Btn>}
    </Empty>
  );
};

const Empty = styled.div`
  height: calc(var(--vh, 1vh) * 60);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: var(--theme-text-l);
  text-align: center;
  color: ${(props) => props.theme.COLOR.TEXT.PRIMARY};
  margin-bottom: 6px;
`;

const SubTitle = styled.p`
  font-size: var(--theme-text-m);
  color: ${(props) => props.theme.COLOR.TEXT.TERTIARY};
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 14px;
`;

const Btn = styled.button`
  font-weight: bold;
  font-size: var(--theme-text-m);
  text-align: center;
  color: ${(props) => props.theme.COLOR.BLUE.PRIMARY};
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  padding: 12px 25px;
`;

export default EmptyMessage;
