import { nf } from 'functions';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ICON } from '../utils/image';

const Review = ({
  rate,
  reviewNum,
  none = true,
  margin,
  type = 'trip',
  totalNum = false,
}) => {
  const [rating, setRating] = useState([0, 0, 0, 0, 0]);
  useEffect(() => {
    const int = parseInt(rate, 10);
    let rest = rate % 1;
    setRating((cur) =>
      cur.map((item, i) => {
        if (i + 1 <= int) return 1;
        else {
          if (rest) {
            rest = 0;
            return -1;
          }
        }
        return 0;
      })
    );
  }, [rate]);

  if (!reviewNum) return <></>;

  if (type === 'trip')
    return (
      <Container margin={margin}>
        {none && (
          <Icon
            src={ICON.REVIEW_CHARACTER.url}
            alt={ICON.REVIEW_CHARACTER.alt}
          />
        )}

        <Ratings>
          {rating.map((icon, i) => {
            if (icon === 1)
              return (
                <Icon
                  key={i}
                  src={ICON.REVIEW_ACTIVE.url}
                  alt={ICON.REVIEW_ACTIVE.alt}
                />
              );
            else if (icon === -1)
              return (
                <Icon
                  key={i}
                  src={ICON.REVIEW_HALF.url}
                  alt={ICON.REVIEW_HALF.alt}
                />
              );
            return (
              <Icon
                key={i}
                src={ICON.REVIEW_INACTIVE.url}
                alt={ICON.REVIEW_INACTIVE.alt}
              />
            );
          })}
        </Ratings>
        <Row>
          <Rate>{rate.toFixed(1)}점</Rate>
          {reviewNum && <ReviewNum>{` (${nf(reviewNum)})`}</ReviewNum>}
        </Row>
      </Container>
    );
  return (
    <Container margin={margin}>
      {none && <Icon src={ICON.GOOGLE_LOGO.url} alt={ICON.GOOGLE_LOGO.alt} />}

      <Ratings>
        {rating.map((icon, i) => {
          if (icon === 1)
            return (
              <Icon
                key={i}
                src={ICON.STAR_ACTIVE.url}
                alt={ICON.STAR_ACTIVE.alt}
                width={!none ? '14' : '20'}
                height={!none ? '14' : '20'}
              />
            );
          else if (icon === -1)
            return (
              <Icon
                key={i}
                src={ICON.STAR_HALF.url}
                alt={ICON.STAR_HALF.alt}
                width={!none ? '14' : '20'}
                height={!none ? '14' : '20'}
              />
            );
          return (
            <Icon
              key={i}
              src={ICON.STAR_INACTIVE.url}
              alt={ICON.STAR_INACTIVE.alt}
              width={!none ? '14' : '20'}
              height={!none ? '14' : '20'}
            />
          );
        })}
      </Ratings>
      <Row>
        <RateGoogle none={none}>{rate.toFixed(1)}점</RateGoogle>
        {totalNum && reviewNum && (
          <ReviewNum>{` (${nf(reviewNum)})`}</ReviewNum>
        )}
      </Row>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  ${(props) => props.margin && `margin-bottom: ${props.margin}px`};
  cursor: pointer;
`;

const Icon = styled.img``;

const Ratings = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const Rate = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: #00aa6c;
`;

const RateGoogle = styled.p`
  font-weight: bold;
  font-size: ${(props) =>
    props.none ? 'var(--theme-text-l)' : 'var(--theme-text-s)'};
  color: var(--theme-text-secondary);
`;

const ReviewNum = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: var(--theme-text-teritary);
`;

const Row = styled.div`
  display: flex;
  gap: 3px;
`;

export default Review;
