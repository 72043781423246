import React from 'react';
import useParticipantStatus from '../../Party/hooks/useParticipantStatus';
import { MESSAGE } from '../../utils/message';
import SimplePopup from './SimplePopup';

const PartyCancelJoinPopup = ({ state }) => {
  const participantMutation = useParticipantStatus({
    key: ['activity', 'party', 'join'],
    content: `파티 참여를 취소했어요.`,
  });
  const onClick = () => {
    participantMutation.mutate({ id: state.id, action: 'cancel' });
  };
  return (
    <SimplePopup
      title={MESSAGE.PARTT_CANCEL_JOIN_POPUP.TITLE}
      content={MESSAGE.PARTT_CANCEL_JOIN_POPUP.CONTENT}
      confirm={MESSAGE.PARTT_CANCEL_JOIN_POPUP.BUTTON}
      cancel="닫기"
      onClick={onClick}
    />
  );
};

export default PartyCancelJoinPopup;
