import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
/* CSS */
import 'swiper/css';
import 'swiper/css/pagination';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-input-range/lib/css/index.css';
import './resources/css/common.css';
import './resources/css/layout.css';
import './resources/css/popup.css';
import './resources/css/calendar.css';
import './Mypage/mypage.css';
import './Region/region.css';
import './property/map.css';
import './Mypage/Wish/wishList.css';
import './resources/css/map.css';
import './resources/css/properties.css';
import './Coupon/coupon.css';

import { theme, GlobalStyle } from './styles/GlobalStyle';
import {
  setDefaultFilters,
  setFiltersOption,
  setHeightForMobile,
} from './utils/utils';
import { UserContext } from './context/auth';
import { getAccount } from './Auth/api';
import Popup from './components/popup/Popup';
import DetailAlert from './components/DetailAlert';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { filterState, signupState } from './atoms/atoms';
import ScrollToTop from './components/ScrollToTop';

import SuspenseForFetching from 'components/SuspenseForFetching';
// import BackgroundLoader from 'components/BackgroundLoader';

const Main = React.lazy(() => import('./Main'));

const SignIn = React.lazy(() => import('./Auth/SignIn/SignIn'));
const SignInNaverRedirect = React.lazy(() =>
  import('./Auth/SignIn/SignInNaverRedirect')
);
const FAQ = React.lazy(() => import('./Faq/Faq'));
const Event = React.lazy(() => import('./Event.function'));
const Follower = React.lazy(() => import('./Follow/Follower.function'));
const Coupon = React.lazy(() => import('./Coupon/Coupon'));
const MyInfo = React.lazy(() => import('./Mypage/MyInfo'));
const MyPage = React.lazy(() => import('./Mypage/MyPage'));
const MypageAlram = React.lazy(() => import('./Mypage/Alram'));
const MypageFollower = React.lazy(() => import('./Mypage/Follower'));
const MypagePoint = React.lazy(() => import('./Mypage/Point'));
const Recent = React.lazy(() => import('./Mypage/Recent'));
const Withdraw = React.lazy(() => import('./Auth/Withdraw/Withdraw'));
const Withdraw2 = React.lazy(() => import('./Auth/Withdraw/Withdraw2'));
const MypageEdit = React.lazy(() => import('./Mypage/Edit.function'));
const Block = React.lazy(() => import('./Mypage/Block'));
const Activities = React.lazy(() => import('./Mypage/Activities'));
const Marketing = React.lazy(() => import('./Mypage/Marketing'));
const Notice = React.lazy(() => import('./Notice/Notice'));
const NoticeView = React.lazy(() => import('./Notice/NoticeView'));
const Region = React.lazy(() => import('./Region/Region'));

const ProfileView = React.lazy(() => import('./Profile/ProfileView'));

const Categories = React.lazy(() => import('./categories'));

const Curation = React.lazy(() => import('./Curation/Curation'));
const Properties = React.lazy(() => import('./property/properties'));
const PropertyMaps = React.lazy(() => import('./property/PropertyMaps'));
const PropertyAround = React.lazy(() => import('./property/around'));
const Search = React.lazy(() => import('./Search'));

const PropertyDetailNew = React.lazy(() => import('./property/detail_new/'));
const PropertyDetailImg = React.lazy(() =>
  import('./property/detail_new/PropertyImgList')
);
const PropertyBookingNew = React.lazy(() => import('./property/booking'));

const PropertyRoomNew = React.lazy(() => import('./property/room'));
const PropertyRoomImg = React.lazy(() => import('./property/room/RoomImgList'));

const AuthAgree = React.lazy(() => import('./Auth/SignIn/AuthAgree'));
const AuthPhone = React.lazy(() => import('./Auth/SignIn/AuthPhone'));
const AuthBirth = React.lazy(() => import('./Auth/SignIn/AuthBirth'));
const BottomSheet = React.lazy(() =>
  import('./components/botomsheet/BottomSheet')
);
const AuthGender = React.lazy(() => import('./Auth/SignIn/AuthGender'));
const AuthNickname = React.lazy(() => import('./Auth/SignIn/AuthNickname'));

const PartyProperty = React.lazy(() => import('./Party/Property'));
const Activity = React.lazy(() => import('./Activity'));
const Anonymous = React.lazy(() => import('./Reservations/anonymous'));

const ReservationDetails = React.lazy(() => import('./Reservations/Detail'));
const ReservationComfirm = React.lazy(() => import('./Reservations/Confirm'));
const ReservationWithdrawNew = React.lazy(() =>
  import('./Reservations/Withdraw')
);

const ReservationWithdrawForPersonal = React.lazy(() =>
  import('./Withdrawal/Withdraw')
);

const ReservationWithdrawnForPersonal = React.lazy(() =>
  import('./Withdrawal/Withdrawal')
);

const WishList = React.lazy(() => import('./Mypage/Wish'));
const ReviewProperty = React.lazy(() =>
  import('./Party/Review/ReviewProperty')
);
const ReviewPerson = React.lazy(() => import('./Party/Review/ReviewPerson'));
const PartyMember = React.lazy(() => import('./Party/Member'));
const NInvitation = React.lazy(() => import('./Npay/Invitation'));

const Exhibition = React.lazy(() => import('./Event/Exhibition'));
const Moments = React.lazy(() => import('./Moment/Moment.re'));
const Moment = React.lazy(() => import('./Moment/SingleMoment'));
import { init } from '@amplitude/marketing-analytics-browser';
import BackgroundLoader from 'components/BackgroundLoader';
import Fullscreen from 'components/fullscreen';

let is_init = false;

const App = () => {
  const { userDispatch } = useContext(UserContext);

  const signup = useRecoilValue(signupState);
  const setFilters = useSetRecoilState(filterState);

  useEffect(() => {
    // openBottobSheet({ type: SHEET_TYPE.PERIOD_SELECTOR, open: true });
    setHeightForMobile();
    setFiltersOption(setDefaultFilters());
    setFilters((cur) => ({ ...cur, ...setDefaultFilters() }));
    window.sessionStorage.setItem(
      'userAgent',
      JSON.stringify({ agent: navigator.userAgent.toLowerCase() })
    );
  }, []);
  useEffect(() => {
    const token = window.localStorage.getItem('access_token');
    if (token) {
      const fetchApi = async () => {
        try {
          const data = await getAccount();
          userDispatch({
            type: 'LOGIN',
            payload: { ...data, provider: signup.provider },
          });

          if (!is_init) {
            const user_email = data && data.email ? data.email : 0;

            init('564f2e54446eaf9177413677017c99c3', user_email, {
              attribution: {
                resetSessionOnNewCampaign: false,
              },
              pageViewTracking: { trackHistoryChanges: 'pathOnly' },
            });
            is_init = true;
          }
        } catch (e) {
          console.log(e);
        }
      };
      fetchApi();
    } else {
      if (!is_init) {
        init('564f2e54446eaf9177413677017c99c3', 0, {
          attribution: {
            resetSessionOnNewCampaign: false,
          },
          pageViewTracking: { trackHistoryChanges: 'pathOnly' },
        });
        is_init = true;
      }
    }
  }, [userDispatch]);

  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <SuspenseForFetching fallback={<BackgroundLoader />}>
            <Routes>
              <Route path="/" element={<Main />} />

              <Route path="/signIn">
                <Route path="" element={<SignIn />} />
                <Route
                  path="naver-redirect"
                  element={<SignInNaverRedirect />}
                />
                <Route path="kakao" element={<AuthPhone />} />
                <Route path="certify" element={<AuthPhone />} />
                <Route path="birthday" element={<AuthBirth />} />
                <Route path="gender" element={<AuthGender />} />
                <Route path="nickname" element={<AuthNickname />} />
                <Route path=":provider" element={<AuthAgree />} />
              </Route>

              <Route path="/categories">
                <Route path=":type1/:type2/:type3" element={<Categories />} />
                <Route path=":type1/:type2" element={<Categories />} />
                <Route path=":type1" element={<Categories />} />
              </Route>

              <Route path="/curation/:id" element={<Curation />} />

              <Route path="/search" element={<Search />} />

              <Route path="/properties">
                <Route path="around" element={<PropertyAround />} />

                <Route path="maps/*" element={<PropertyMaps />} />

                <Route
                  path=":propertyId/detail"
                  element={<PropertyDetailNew />}
                />
                <Route
                  path=":propertyId/detail/img"
                  element={<PropertyDetailImg />}
                />

                <Route
                  path=":propertyId/rooms/img/:roomtypeId/:rateplanId"
                  element={<PropertyRoomImg />}
                />
                <Route
                  path=":propertyId/rooms/:roomtypeId/:rateplanId"
                  element={<PropertyRoomNew />}
                />

                <Route
                  path=":propertyId/booking/:roomtypeId/:rateplanId"
                  element={<PropertyBookingNew />}
                />
                <Route path="*" element={<Properties />} />
              </Route>

              <Route path="/moments">
                <Route path="" element={<Moments />} />
                <Route path=":id" element={<Moment />} />
              </Route>

              <Route path="/faq" element={<FAQ />} />

              <Route path="/event" element={<Event />} />

              <Route path="/mypage">
                <Route path="" element={<MyPage />} />
                <Route path="info" element={<MyInfo />} />
                <Route path="alram" element={<MypageAlram />} />
                <Route path="block" element={<Block />} />
                <Route path="activity" element={<Activity />} />
                <Route path="marketing" element={<Marketing />} />
                <Route path="activities" element={<Activities />} />
                <Route path="activities" element={<Activities />} />
                <Route
                  path="follower"
                  element={<MypageFollower />}
                  location="state"
                />
                <Route path="wishlist" element={<WishList />} />
                <Route path="edit" element={<MypageEdit />} />
                <Route path="recent" element={<Recent />} />
                <Route path="withdraw" element={<Withdraw />} />
                <Route path="withdraw2" element={<Withdraw2 />} />

                <Route path="point-logs" element={<MypagePoint />} />
                <Route path="coupons" element={<Coupon />} />
              </Route>

              <Route path="/notice">
                <Route path="" element={<Notice />} />
                <Route path=":id" element={<NoticeView />} />
              </Route>

              <Route path="/region" element={<Region />} />

              <Route path="/profile/">
                <Route path=":accountId" element={<ProfileView />} />
                <Route path=":id/follower" element={<Follower />} />
              </Route>

              <Route path="/party">
                <Route path=":id/property" element={<PartyProperty />} />
                <Route
                  path="reservation/:id/detail"
                  element={<ReservationDetails />}
                />
                <Route
                  path=":id/review/property"
                  element={<ReviewProperty />}
                />
                <Route path=":id/review/person" element={<ReviewPerson />} />
                <Route path=":id/member" element={<PartyMember />} />
              </Route>

              <Route path="/reservation">
                <Route path="anonymous" element={<Anonymous />} />

                <Route path=":id/detail" element={<ReservationDetails />} />
                <Route path=":id/confirm" element={<ReservationComfirm />} />
                <Route
                  path=":reservation_id/withdraw"
                  element={<ReservationWithdrawForPersonal />}
                />
                <Route
                  path=":reservation_id/withdrawn"
                  element={<ReservationWithdrawnForPersonal />}
                />
              </Route>

              <Route path="/dutch_pay">
                <Route path=":id/member/:member_id" element={<NInvitation />} />
                <Route
                  path=":id/member/:member_id/withdraw/:reservation_id"
                  element={<ReservationWithdrawNew />}
                />
              </Route>
              <Route path="/exhibition">
                <Route path=":id" element={<Exhibition />} />
              </Route>
            </Routes>
          </SuspenseForFetching>
          <Fullscreen />
          <Popup />
          <BottomSheet />
          <DetailAlert />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
        <ScrollToTop />
      </BrowserRouter>
    </>
  );
};

export default App;
