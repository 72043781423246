import Checkin from '../resources/img/icon/checkin.svg';
import InstagramWhite from '../resources/img/icon-instagram-white.png';
import InstagramBlack from '../resources/img/icon-instagram-black.png';
import LinkedWhite from '../resources/img/icon-linked-white.png';
import LinkedBlack from '../resources/img/icon-linked-black.png';
import Hamburger from '../resources/img/icon/Icon-hambuger.svg';
import PlusWhite from '../resources/img/icon-plus-white.png';
import PlusBlack from '../resources/img/icon-plus.png';
import CommonPeople from '../resources/img/icon/icon-poeple.svg';
import CommonCalendar from '../resources/img/icon/icon-calendar.svg';
import BoldPeople from '../resources/img/icon/icon-people-bold.svg';
import BoldCalendar from '../resources/img/icon/icon-calendar-bold.svg';
import WishList from '../resources/img/icon/icon-wish.svg';
import Reservation from '../resources/img/icon/icon-reservation.svg';
import Coupon from '../resources/img/icon/icon-coupon.svg';
import Point from '../resources/img/icon/icon-point.svg';
import SignIn from '../resources/img/icon-signin.png';
import SearchRecentDelete from '../resources/img/search-recent-delete.png';
import MapRedirect from '../resources/img/icon-map-redirect.png';
import MapPosition from '../resources/img/icon-map-position.png';
import Sorting from '../resources/img/icon/icon-recommend.svg';
import Filter from '../resources/img/icon/icon-filter.svg';
import FilterBlue from '../resources/img/icon/icon-filter-blue.svg';
import Categories from '../resources/img/icon/icon-categories.svg';
import Map from '../resources/img/icon/icon-map.svg';
import WeatherClean from '../resources/img/icon/icon-weather-clean.svg';
import WeatherDust from '../resources/img/icon/icon-weather-dust.svg';
import WeatherCloud from '../resources/img/icon/icon-weather-cloud.svg';
import WeatherLittleCloud from '../resources/img/icon/icon-weather-little_cloud.svg';
import WeatherRain from '../resources/img/icon/icon-weather-rain.svg';
import WeatherSnow from '../resources/img/icon/icon-weather-snow.svg';
import TopButton from '../resources/img/icon-top-button.png';
import BookmarkOff from '../resources/img/icon/icon-bookmark-inactive.svg';
import BookmarkOn from '../resources/img/icon/icon-bookmark-active.svg';
import Back from '../resources/img/icon/icon-back.svg';
import BackWhite from '../resources/img/icon/icon-back-white.svg';
import Lodging from '../resources/img/icon-lodging.png';
import Play from '../resources/img/icon-moment-play.svg';
import Pause from '../resources/img/icon-moment-paused.svg';
import Muted from '../resources/img/icon-moment-muted.svg';
import Volume from '../resources/img/icon-moment-volume.svg';

import Premium from '../resources/img/icon/icon-premium.svg';
import Motel from '../resources/img/icon/icon-motel.svg';
import Hotel from '../resources/img/icon/icon-hotel.svg';
import Pension from '../resources/img/icon/icon-pension.svg';
import Resort from '../resources/img/icon/icon-resort.svg';
import Camping from '../resources/img/icon/icon-camping.svg';
import GuestHouse from '../resources/img/icon/icon-geusthouse.svg';
import Hanok from '../resources/img/icon/icon-hanok.svg';

import ReviewCharacter from '../resources/img/icon/icon-review-character.svg';
import ReviewActive from '../resources/img/icon/icon-review-active.svg';
import ReviewInactive from '../resources/img/icon/icon-review-inactive.svg';
import ReviewHalf from '../resources/img/icon/icon-review-half.svg';
import ArrowMorw from '../resources/img/icon/icon-arrow-more.svg';
import List from '../resources/img/icon/icon-list.svg';
import ProfileEdit from '../resources/img/icon/icon-edit.svg';
import NoProfile from '../resources/img/icon/icon-no-profile.svg';
import ProfilCamera from '../resources/img/icon/icon-profile-camera.svg';
import Share from '../resources/img/icon/icon-share.svg';
import PointTip from '../resources/img/icon/icon-point-popup.svg';
import RefundBlue from '../resources/img/icon/icon-refund-tip-blue.svg';
import RefundRed from '../resources/img/icon/icon-refund-tip-red.svg';
import Alram from '../resources/img/icon/icon-alram.svg';
import More from '../resources/img/icon/icon-more.svg';
import Following from '../resources/img/icon/icon-folowing.svg';
import Follower from '../resources/img/icon/icon-follower.svg';
import Link from '../resources/img/icon/icon-link.svg';
import Check from '../resources/img/icon/icon-check.svg';
import CheckBlue from '../resources/img/icon/icon-check-blue.svg';
import CheckTotal from '../resources/img/icon/icon-check-total.svg';
import Toggle from '../resources/img/icon/icon-toggle-open.svg';
import CheckTotalBlue from '../resources/img/icon/icon-check-total-blue.svg';
import ToggleOpenRed from '../resources/img/icon/icon-toggle-open-red.svg';
import ToggleCloseRed from '../resources/img/icon/icon-toggle-close-red.svg';
import ToggleOpenBlue from '../resources/img/icon/icon-toggle-open-blue.svg';
import ToggleCloseBlue from '../resources/img/icon/icon-toggle-close-blue.svg';
import InputReset from '../resources/img/icon/icon-input-reset.svg';
import MapBlack from '../resources/img/icon/icon-map-black.svg';
import Tell from '../resources/img/icon/icon-tell.svg';
import ArrowRight from '../resources/img/icon/icon-arrow-right.svg';
import Circle from '../resources/img/icon/icon-circle.svg';
import Delete from '../resources/img/icon/icon-delete.svg';
import RadioOff from '../resources/img/icon/icon-radio-off.svg';
import RadioOn from '../resources/img/icon/icon-radio-on.svg';

import ReviewBadOff from '../resources/img/icon/icon-review-bad.svg';
import ReviewBadOn from '../resources/img/icon/icon-review-bad-red.svg';

import ReviewGoodOn from '../resources/img/icon/icon-review-good.svg';
import ReviewGoodOff from '../resources/img/icon/icon-review-good-inactive.svg';
import ReviewGreatOn from '../resources/img/icon/icon-review-great.svg';
import ReviewGreatOff from '../resources/img/icon/icon-review-great-inactive.svg';

import ReviewSafety from '../resources/img/icon/icon-safety.svg';
import ReviewPromise from '../resources/img/icon/icon-review-promise.svg';
import ReviewFunny from '../resources/img/icon/icon-review-funny.svg';

import CheckSmall from '../resources/img/icon/icon-check-small.svg';
import MoreCol from '../resources/img/icon/icon-more-col.svg';

import Card from '../resources/img/icon/icon-card.svg';
import Kakaopay from '../resources/img/icon/icon-kakaopay.svg';
import Naverpay from '../resources/img/icon/icon-naverpay.svg';
import Payco from '../resources/img/icon/icon-payco.svg';
import Time from '../resources/img/icon/icon-time.svg';

import FirstVisit from '../resources/img/icon/icon-firstvisit.png';
import DutchApp from '../resources/img/icon/icon-dutchpay.png';
import Banner from 'resources/img/icon/icon-banner.png';
import Download from 'resources/img/icon/icon-download.svg';
import ExpectPoint from 'resources/img/icon/icon-point-expect.svg';

import SearchBlue from '../resources/img/icon/icon-search-blue.svg';
import SearchGrey from '../resources/img/icon/icon-search-grey.svg';
import SearchProperty from '../resources/img/icon/icon-search-property.svg';
import DeleteRecent from '../resources/img/icon/icon-delete-recent.svg';
import SigninIcon from '../resources/img/icon/icon-signin.png';

import MomentProperty from '../resources/img/icon/icon-moment-property.svg';
import MomentLocation from '../resources/img/icon/icon-moment-location.svg';
import LikeInactive from '../resources/img/icon/icon-like-inactive.svg';
import LikeActive from '../resources/img/icon/icon-like-active.svg';
import MomentChat from '../resources/img/icon/icon-chat.svg';
import MomentShare from '../resources/img/icon/icon-moment-share.svg';
import MoreWhite from '../resources/img/icon/icon-moment-more.svg';
import CommentLikeInactive from '../resources/img/icon/icon-comment-like-inactive.svg';
import CommentLikeActive from '../resources/img/icon/icon-comment-like-active.svg';
import CommentChat from '../resources/img/icon/icon-comment-chat.svg';
import CommentMore from '../resources/img/icon/icon-comment-more.svg';
import CommentBack from '../resources/img/icon/icon-comment-back.svg';
import Close from '../resources/img/icon/icon-close.svg';
import CloseWhite from '../resources/img/icon/icon-close-white.svg';
import Home from '../resources/img/icon/icon-home.svg';
import Recent from '../resources/img/icon/icon-recent.svg';
import ReservationNoMember from '../resources/img/icon/icon-reservation-nonmember.svg';

// property
import PropertyHome from '../resources/img/icon/icon-property-home.svg';
import PropertyBook from '../resources/img/icon/icon-property-book.svg';
import PropertyBookOn from '../resources/img/icon/icon-property-book-on.svg';
import PropertyZoom from '../resources/img/icon/icon-property-zoom.svg';
import PropertyCall from '../resources/img/icon/icon-property-call.svg';
import PropertyShare from '../resources/img/icon/icon-property-share.svg';
import PropertyCheckin from '../resources/img/icon/icon-property-checkin.svg';
import PropertyPeople from '../resources/img/icon/icon-property-people.svg';
import PropertyParkable from '../resources/img/icon/icon-property-parkable.svg';
import PropertyNonParkable from '../resources/img/icon/icon-property-nonparkable.svg';
import GoogleReview from '../resources/img/icon/icon-google-review.svg';
import Star from '../resources/img/icon/icon-star-active.svg';
import StarInactive from '../resources/img/icon/icon-star-inactive.svg';
import StarHalf from '../resources/img/icon/icon-star-half.svg';
import PropertyPriceIcon from '../resources/img/icon/Icon-property-recommend_price.svg';
import TouchcheckBannerImg from '../resources/img/touchcheck_banner.png';

//프리뷰

import ReviewPreview from '../resources/img/preview/review-preview.png';
import NoImg from '../resources/img/icon/icon-no-img.png';

export const ICON = {
  INSTAGRAM: {
    white: InstagramWhite,
    black: InstagramBlack,
    alt: 'instagram icon',
  },
  LINKED: {
    white: LinkedWhite,
    black: LinkedBlack,
    alt: 'linked icon',
  },
  HAMBUGER: {
    url: Hamburger,
    alt: 'hambuger icon',
  },
  Plus: {
    white: PlusWhite,
    black: PlusBlack,
    alt: 'plus icon',
  },
  COMMON_PEOPLE: {
    url: CommonPeople,
    alt: '인원수 선택',
  },
  COMMON_CALENDAR: {
    url: CommonCalendar,
    alt: '날짜 선택',
  },
  BOLD_PEOPLE: {
    url: BoldPeople,
    alt: '인원수 선택',
  },
  BOLD_CALENDAR: {
    url: BoldCalendar,
    alt: '날짜 선택',
  },

  WISHLIST: {
    url: WishList,
    alt: '위시리스트',
  },
  RESERVATION: {
    url: Reservation,
    alt: '예약내역',
  },
  RESERVATION_NOMEMBER: {
    url: ReservationNoMember,
    alt: '비회원 에약 내역',
  },
  COUPON: {
    url: Coupon,
    alt: '쿠폰',
  },
  POINT: {
    url: Point,
    alt: '포인트',
  },
  SIGNIN: {
    url: SignIn,
    alt: '로그인',
  },
  MAP_REDIRECT: {
    url: MapRedirect,
    alt: 'redirect',
  },
  MAP_POSITION: {
    url: MapPosition,
    alt: '현재 위치',
  },
  SORTING: {
    url: Sorting,
    alt: '정렬',
  },
  FILTER: {
    url: Filter,
    alt: '필터',
  },
  FILTER_BLUE: {
    url: FilterBlue,
    alt: '필터',
  },
  CATEGORIES: {
    url: Categories,
    alt: '카테고리',
  },
  MAP: {
    url: Map,
    alt: '지도',
  },
  WEATHER_CLEAR: {
    url: WeatherClean,
    alt: '맑은 날씨',
  },
  WEATHER_DUST: {
    url: WeatherDust,
    alt: '화창한 날씨',
  },
  WEATHER_CLOUD: {
    url: WeatherCloud,
    alt: '구름 낀 날씨',
  },
  WEATHER_FEW_CLOUD: {
    url: WeatherLittleCloud,
    alt: '구름 조금 낀 날씨',
  },
  WEATHER_RAIN: {
    url: WeatherRain,
    alt: '비오는 날씨',
  },
  WEATHER_SNOW: {
    url: WeatherSnow,
    alt: '눈오는 날씨',
  },
  TOP_BUTTON: {
    url: TopButton,
    alt: '위로가기 버튼',
  },
  BOOKMARK_OFF: {
    url: BookmarkOff,
    alt: '북마크 off',
  },

  BOOKMARK_ON: {
    url: BookmarkOn,
    alt: '북마크 on',
  },
  LODGING: {
    url: Lodging,
    alt: 'lodging',
  },
  PLAY: {
    url: Play,
    alt: 'play button',
  },
  PAUSE: {
    url: Pause,
    alt: 'pause button',
  },
  MUTED: {
    url: Muted,
    alt: 'muted',
  },
  VOLUME: {
    url: Volume,
    alt: 'volume',
  },
  BACK: {
    url: Back,
    alt: '뒤로가기',
  },
  BACK_WHITE: {
    url: BackWhite,
    alt: '뒤로가기',
  },
  REVIEW_CHARACTER: {
    url: ReviewCharacter,
    alt: '리뷰 아이콘',
  },
  REVIEW_ACTIVE: {
    url: ReviewActive,
    alt: '리뷰 아이콘',
  },
  REVIEW_INACTIVE: {
    url: ReviewInactive,
    alt: '리뷰 아이콘',
  },
  REVIEW_HALF: {
    url: ReviewHalf,
    alt: '리뷰 아이콘',
  },
  ARROW_MORE: {
    url: ArrowMorw,
    alt: '더보기',
  },
  LIST: {
    url: List,
    alt: '목록',
  },
  PROFILE_EDIT: {
    url: ProfileEdit,
    alt: '프로필 수정',
  },
  NO_PROFILE: {
    url: NoProfile,
    alt: '기본 프로필',
  },
  PROFILE_CAMERA: {
    url: ProfilCamera,
    alt: '프로필 사진 변경',
  },
  PREMIUM: {
    url: Premium,
    alt: '프리미엄 숙소',
  },
  MOTEL: {
    url: Motel,
    alt: '모텔',
  },
  HOTEL: {
    url: Hotel,
    alt: '호텔',
  },
  PENSION: {
    url: Pension,
    alt: '펜션/풀빌라',
  },
  RESORT: {
    url: Resort,
    alt: '리조트',
  },
  CAMPING: {
    url: Camping,
    alt: '캠핑/글램핑',
  },
  GUESTHOUSE: {
    url: GuestHouse,
    alt: '게스트하우스',
  },
  HANOK: {
    url: Hanok,
    alt: '한옥',
  },
  SHARE: {
    url: Share,
    alt: '카카오톡 공유',
  },
  TOOLTIP: {
    url: PointTip,
    alt: '포인트 안내',
  },
  ALRAM: {
    url: Alram,
    alt: '알람',
  },
  MORE: {
    url: More,
    alt: '더보기',
  },
  FOLLOWING: {
    url: Following,
    alt: '팔로우취소',
  },
  FOLLOWER: {
    url: Follower,
    alt: '팔로우',
  },
  LINK: {
    url: Link,
    alt: '링크',
  },
  CHECK: {
    url: Check,
    alt: '모두 읽음',
  },
  CHECK_BLUE: {
    url: CheckBlue,
    alt: '체크',
  },
  CHECK_TOTAL: {
    url: CheckTotal,
    alt: '전체 동의',
  },
  CHECK_TOTAL_BLUE: {
    url: CheckTotalBlue,
    alt: '전체 동의',
  },
  REFUND_BLUE: {
    url: RefundBlue,
    alt: '툴팁',
  },
  REFUND_RED: {
    url: RefundRed,
    alt: '툴팁',
  },
  TOGGLE_OPEN_RED: {
    url: ToggleOpenRed,
    alt: '토글 오픈',
  },
  TOGGLE_CLOSE_RED: {
    url: ToggleCloseRed,
    alt: '토글 닫기',
  },
  TOGGLE_OPEN_BLUE: {
    url: ToggleOpenBlue,
    alt: '토글 오픈',
  },
  TOGGLE_CLOSE_BLUE: {
    url: ToggleCloseBlue,
    alt: '토글 닫기',
  },
  INPUT_RESET: {
    url: InputReset,
    alt: '초기화',
  },
  TELL: {
    url: Tell,
    alt: '전화',
  },
  MAP_BLACK: {
    url: MapBlack,
    alt: '지도',
  },
  TOGGLE: {
    url: Toggle,
    alt: '토클',
  },
  ARROW_RIGHT: {
    url: ArrowRight,
    alt: '링크',
  },
  CIRCLE: {
    url: Circle,
    alt: '원',
  },
  DELETE: {
    url: Delete,
    alt: '파티 삭제',
  },
  RADIO_OFF: {
    url: RadioOff,
    alt: '라디오 off',
  },
  RADIO_ON: {
    url: RadioOn,
    alt: '라디오 on',
  },
  REVIEW_BAD_OFF: {
    url: ReviewBadOff,
    alt: '별로였어요 버튼 off',
  },
  REVIEW_BAD_ON: {
    url: ReviewBadOn,
    alt: '별로였어요 버튼 On',
  },
  REVIEW_GOOD_OFF: {
    url: ReviewGoodOff,
    alt: '좋았어요 버튼 off',
  },
  REVIEW_GOOD_ON: {
    url: ReviewGoodOn,
    alt: '좋았어요 버튼 on',
  },
  REVIEW_GREAT_OFF: {
    url: ReviewGreatOff,
    alt: '아주 좋았어요 버튼 off',
  },
  REVIEW_GREAT_ON: {
    url: ReviewGreatOn,
    alt: '아주 좋았어요 버튼 on',
  },
  SAFETY: {
    url: ReviewSafety,
    alt: '안전',
  },
  REVIEW_PROMISE: {
    url: ReviewPromise,
    alt: '약속',
  },
  REVIEW_FUNNY: {
    url: ReviewFunny,
    alt: '재미',
  },
  CHECK_SMALL: {
    url: CheckSmall,
    alt: '체크',
  },
  MORE_COL: {
    url: MoreCol,
    alt: '더보기',
  },
  CARD: {
    url: Card,
    alt: '카드',
  },
  KAKAOPAY: {
    url: Kakaopay,
    alt: '카카오페이',
  },
  NAVERPAY: {
    url: Naverpay,
    alt: '네이버페이',
  },
  PAYCO: {
    url: Payco,
    alt: '페이코',
  },
  TIME: {
    url: Time,
    alt: '시간',
  },
  BANNER: {
    url: Banner,
    alt: '쿠폰 배너',
  },
  DOWNLOAD: {
    url: Download,
    alt: '쿠폰 다운로드',
  },
  EXPECT_POINT: {
    url: ExpectPoint,
    alt: '시간',
  },
  SEARCH: {
    url: SearchBlue,
    alt: '검색',
  },
  SEARCH_GREY: {
    url: SearchGrey,
    alt: '검색',
  },
  SEARCH_PROPERTY: {
    url: SearchProperty,
    alt: '숙소',
  },
  DELETE_RECENT: {
    url: DeleteRecent,
    alt: '삭제',
  },
  MOMENT_PROPERTY: {
    url: MomentProperty,
    alt: '숙소',
  },
  MOMENT_LOCATION: {
    url: MomentLocation,
    alt: '지역',
  },
  LIKE_INACTIVE: {
    url: LikeInactive,
    alt: '좋아요 눌러주세요.',
  },
  LIKE_ACTIVE: {
    url: LikeActive,
    alt: '좋아요한 모먼트입니다.',
  },
  CHAT: {
    url: MomentChat,
    alt: '댓글을 남겨주세요.',
  },
  MOMENT_SHARE: {
    url: MomentShare,
    alt: '친구에서 모먼트를 공유해 주세요.',
  },
  MORE_WHITE: {
    url: MoreWhite,
    alt: '메뉴 더보기',
  },
  COMMENT_LIKE_INACTIVE: {
    url: CommentLikeInactive,
    alt: '좋아요를 눌러주세요.',
  },
  COMMENT_LIKE_ACTIVE: {
    url: CommentLikeActive,
    alt: '좋아요',
  },
  COMMENT_CHAT: {
    url: CommentChat,
    alt: '대댓글 목록을 볼 수 있습니다.',
  },
  COMMENT_MORE: {
    url: CommentMore,
    alt: '더보기',
  },
  COMMENT_BACK: {
    url: CommentBack,
    alt: '닫기',
  },
  CLOSE: {
    url: Close,
    alt: '닫기',
  },
  CLOSE_WHITE: {
    url: CloseWhite,
    alt: '닫기',
  },
  HOME: {
    url: Home,
    alt: '홈으로',
  },
  RECENT: {
    url: Recent,
    alt: '최근 본 숙소',
  },
  PROPERTY_HOME: {
    url: PropertyHome,
    alt: '홈',
  },
  PROPERTY_BOOK: {
    url: PropertyBook,
    alt: '북마크',
  },
  PROPERTY_BOOK_ON: {
    url: PropertyBookOn,
    alt: '북마크',
  },
  PROPERTY_ZOOM: {
    url: PropertyZoom,
    alt: '이미지 더 보기',
  },
  PROPERTY_CALL: {
    url: PropertyCall,
    alt: '숙소 문의',
  },
  PROPERTY_SHARE: {
    url: PropertyShare,
    alt: '숙소 공유',
  },
  PROPERTY_CHECKIN: {
    url: PropertyCheckin,
    alt: '체크인',
  },
  PROPERTY_PEOPLE: {
    url: PropertyPeople,
    alt: '인원',
  },
  PROPERTY_PARKABLE: {
    url: PropertyParkable,
    alt: '주차 가능',
  },
  PROPERTY_NONPARKABLE: {
    url: PropertyNonParkable,
    alt: '주차 불가',
  },
  GOOGLE_LOGO: {
    url: GoogleReview,
    alt: '구글 로고',
  },
  STAR_ACTIVE: {
    url: Star,
    alt: '별점 1점',
  },
  STAR_INACTIVE: {
    url: StarInactive,
    alt: '별점 0점',
  },
  STAR_HALF: {
    url: StarHalf,
    alt: '별점 0.5점',
  },
  RECOMMEND_PRICE: {
    url: PropertyPriceIcon,
    alt: '가격',
  },
};

export const IMG = {
  LOGO: {
    url: Checkin,
    alt: '로고',
  },
  SEARCH_RECENT_DELETE: {
    url: SearchRecentDelete,
    alt: '삭제',
  },
  FIRST_VISIT: {
    url: FirstVisit,
    alt: '첫 방문',
  },
  DUTCH_APP: {
    url: DutchApp,
    alt: '더치페이 기능을 앱에서 이용해 보세요.',
  },
  SIGNIN: {
    url: SigninIcon,
    alt: '이미지',
  },
  NO_IMG: {
    url: NoImg,
    alt: '대체 이미지',
  },

  TC_BANNER: {
    url: TouchcheckBannerImg,
    alt: '터치체크 무료 시작 배너',
  },
};

export const PREVIEW = {
  REVIEW: {
    url: ReviewPreview,
    alt: '프리뷰',
  },
};
