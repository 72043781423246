import React, { useState } from 'react';
import SimpleFullscreen from './SimpleFullscreen';
import { fullScreenState } from 'atoms/atoms';
import { useRecoilValue } from 'recoil';
import { Swiper, SwiperSlide } from 'swiper/react';
import LazyImg from 'components/atoms/LazyImg';
import styled from 'styled-components';

const PropertyImgsFullscreen = () => {
  // eslint-disable-next-line no-unused-vars
  const {
    state: { data, order },
  } = useRecoilValue(fullScreenState);
  const [index, setIndex] = useState(order + 1);

  return (
    <SimpleFullscreen
      titleNo={true}
      title={`${index} / ${data.length}`}
      background="#000000"
    >
      <Container>
        <Swiper
          slidesPerView={1}
          slidesPerGroup={1}
          pagination={false}
          onSwiper={(swiper) => {
            swiper.slideTo(order);
          }}
          onSlideChangeTransitionEnd={(swiper) => {
            setIndex(swiper.activeIndex + 1);
          }}
          wrapperTag="ul"
        >
          {data.map((img) => (
            <SwiperSlide key={img.order}>
              <LazyImg
                src={img.url}
                alt={img.description}
                width="375"
                height="219"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </SimpleFullscreen>
  );
};

const Container = styled.section`
  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1.7 / 1;
  }
  height: 100%;
  display: flex;
  align-items: center;
`;

export default PropertyImgsFullscreen;
