import React from 'react';
import styled from 'styled-components';
import ReactPortal from '../portal/ReactPortal';
import { ReactComponent as CheckinLogo } from '../../resources/img/icon/checkin-small.svg';
import { ReactComponent as LinkIcon } from '../../resources/img/icon/icon-first-link.svg';
import { Container } from './styles';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { popupState } from '../../atoms/atoms';
import { setLocalStorageExpiration } from '../../utils/utils';

const AppMovePopup = ({
  title,
  notice,
  src,
  link = '',
  confrim = '앱 이용하기',
  disagree = '오늘은 웹으로 볼게요.',
  session = false,
}) => {
  const [popup, setPopup] = useRecoilState(popupState);
  const resetPopup = useResetRecoilState(popupState);
  const closePopup = () => {
    setPopup({ ...popup, open: false });
    setTimeout(() => {
      resetPopup();
    }, 300);
    if (session) {
      setLocalStorageExpiration({
        key: 'firstVisit',
        value: true,
        ttl: 86400000,
      });
    }
  };
  const onClick = () => {
    window.open(process.env.REACT_APP_DEEP_LINK);
    closePopup();
  };
  const onClickLink = () => {
    if (link) {
      window.location.href = 'https://www.checkinn.kr' + link;
      closePopup();
    }
  };
  return (
    <ReactPortal wrapperId="react-portal-modal">
      <Container isShowing={popup.open}>
        <PopupBox>
          <PopupTitle>
            <CheckinLogo /> {title}
          </PopupTitle>
          <Img src={src} alt="앱에서 이용해 주세요" />
          <Text onClick={onClickLink}>
            {notice}
            {link && <LinkIcon />}
          </Text>
          <AppMoveButton onClick={onClick}>{confrim}</AppMoveButton>
          <WebButton onClick={closePopup}>{disagree}</WebButton>
        </PopupBox>
      </Container>
    </ReactPortal>
  );
};

const PopupBox = styled.section`
  width: calc(100vw - 40px);
  max-width: 460px;
  background: #ffffff;
  border-radius: 30px;
  padding: 44px 15px 30px 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  position: relative;
  text-align: center;
`;

const PopupTitle = styled.h2`
  font-weight: bold;
  font-size: var(--theme-text-xl);
  line-height: 1.87rem;
  color: var(--theme-text-primary);
  white-space: pre-line;
`;

const Img = styled.img`
  height: 222px;
  /* background: url(${(props) => props.src}) no-repeat center; */
  margin-bottom: 8px;
`;

const Text = styled.span`
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: var(--theme-text-m);
  line-height: 1.375rem;
  color: #5598ff;
  margin-bottom: 30px;
`;

const AppMoveButton = styled.button`
  background: var(--theme-button-active);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 27px;
  font-weight: bold;
  font-size: var(--theme-text-xl);
  line-height: 1.623rem;
  color: #ffffff;
  padding: 15px;
  margin: 0 25px 24px;
  cursor: pointer;
`;

const WebButton = styled.button`
  font-weight: bold;
  font-size: var(--theme-text-l);
  line-height: 1rem;
  color: var(--theme-text-teritary);
  background: transparent;
  cursor: pointer;
`;

export default AppMovePopup;
