import React from 'react';
import { useNavigate } from 'react-router-dom';
import useDelReservation from '../../Reservations/hooks/useDelReservation';
import SimplePopup from './SimplePopup';

const ReservationDeletePopup = ({ state }) => {
  const deleteMutation = useDelReservation();
  const navigate = useNavigate();
  const onClick = () => {
    deleteMutation.mutate(
      { id: state.id },
      {
        onSuccess: () => {
          navigate('/');
        },
      }
    );
  };
  return (
    <SimplePopup
      title="예약 내역을 삭제할까요?"
      content={`예약 내역을 삭제하면 영원히 볼 수 없어요!`}
      cancel="그냥 냅둘래요"
      confirm="네, 삭제할게요"
      onClick={onClick}
    />
  );
};

export default ReservationDeletePopup;
