import React from 'react';
import useWithdraw from '../../Auth/hooks/useWithdraw';
import { MESSAGE } from '../../utils/message';
import SimplePopup from './SimplePopup';

const WithdrawPopup = ({ state }) => {
  const withdrawmutate = useWithdraw();
  const onClick = () => {
    withdrawmutate.mutate({ ...state });
  };
  return (
    <SimplePopup
      title={MESSAGE.WITHDRAW.TITLE}
      content={MESSAGE.WITHDRAW.CONTENT}
      cancel="더 써볼래요"
      confirm="안녕히 계세요!"
      onClick={onClick}
    />
  );
};

export default WithdrawPopup;
