import { AxiosError } from 'axios';
import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import EmptyMessage from './EmptyMessage';

const Errorhandler = ({ error }) => {
  const navigate = useNavigate();
  if (error instanceof AxiosError) {
    const { code, message } = error.response.data.error;
    if (code === 400) {
      return (
        <EmptyMessage
          title="잘못 된 접근입니다🥲"
          subTitle={`${message}`}
          button="홈으로"
          onClick={() => navigate('/')}
        />
      );
    }
    return (
      <EmptyMessage
        title="잘못 된 접근입니다🥲"
        subTitle={`${message}`}
        button="홈으로"
        onClick={() => navigate('/')}
      />
    );
  }
  return (
    <EmptyMessage
      title="잘못 된 접근입니다🥲"
      button="홈으로"
      onClick={() => navigate('/')}
    />
  );
};

const SuspenseForFetching = ({ children, fallback }) => {
  return (
    <ErrorBoundary FallbackComponent={Errorhandler}>
      <Suspense fallback={fallback}>{children}</Suspense>
    </ErrorBoundary>
  );
};

export default SuspenseForFetching;
