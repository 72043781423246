import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import API from '../../API';
import { snackbarState } from '../../atoms/atoms';
import { AxiosError } from 'axios';

const usePartyStatus = ({ key, content }) => {
  const queryClient = useQueryClient();
  const setSnackbar = useSetRecoilState(snackbarState);
  const fetchApi = async ({ id, action }) => {
    await API.put(`party/${id}/status?action=${action}`);
  };
  return useMutation(fetchApi, {
    onSuccess: () => {
      setSnackbar({ open: true, content });
      return queryClient.invalidateQueries(key);
    },
    onError: (e) => {
      if (e instanceof AxiosError) {
        if (e.response.data.error.code === 400) {
          setSnackbar({ open: true, content: '이미 취소한 파티입니다.' });
          return queryClient.invalidateQueries(key);
        }
      }
    },
  });
};

export default usePartyStatus;
