export const isAndroid = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('checkin_app_aos');
};

export const isIos = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('checkin_app_ios');
};

export const checkWebview = () => {
  return isAndroid() || isIos();
};

export const checkToken = () => {
  return !!document.getElementById('auth-token').value;
};

export const appMove = ({ page_type, page_id, parameter = null }) => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes('checkin_app_aos')) {
    const result = parameter
      ? JSON.stringify({ page_type, page_id, ...parameter })
      : JSON.stringify({ page_type, page_id });
    window.AppEvent.app_move(result);
    return;
  }

  if (userAgent.includes('checkin_app_ios')) {
    const result = parameter
      ? { page_type, page_id, ...parameter }
      : { page_type, page_id };
    window.webkit.messageHandlers.app_move.postMessage({
      ...result,
    });
    return;
  }
};

export const appBack = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes('checkin_app_aos')) {
    window.AppEvent.app_back('');
  }
  if (userAgent.includes('checkin_app_ios')) {
    window.webkit.messageHandlers.app_back.postMessage('');
  }
};
