import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { MESSAGE } from '../../utils/message';
import SimplePopup from './SimplePopup';

const LoginPopup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const onClick = () => {
    navigate('/signIn', {
      state: { from: location.pathname + location.search },
    });
  };
  return (
    <SimplePopup
      title={MESSAGE.LOGIN.TITLE}
      content={MESSAGE.LOGIN.CONTENT}
      cancel="닫기"
      confirm="로그인"
      onClick={onClick}
    />
  );
};

export default LoginPopup;
