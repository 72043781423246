export const COLOR = {
  BLUE: {
    PRIMARY: '#0080FF',
    SECONDARY: '#00B2FF',
    TERTIARY: '#0080FF Opacity 30',
  },
  RED: {
    PRIMARY: '#ED4956',
    SECONDARY: '#FF007A',
    TERTIARY: '#FF007A Opacity 30',
  },
  YELLOW: {
    PRIMARY: '#FFF626',
    SECONDARY: '#FFFF8D',
  },
  GREEN: {
    PRIMARY: '#00F355',
  },
  TEXT: {
    PRIMARY: '#463E3F',
    SECONDARY: '#909090',
    TERTIARY: '#C4C4C4',
  },
  ICON: {
    PRIMARY: '#E5E7E9',
    SECONDARY: '#B0B4B7',
  },
  BUTTON: {
    CANCEL: '#D2D2D2',
    DISABLED: '#F1F1F1',
  },
  BOTTOM_TAB: {
    ICON: '#555555',
  },
  SKELTON: {
    CURATION: '#bdc3c7',
  },
};
