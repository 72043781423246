import React from 'react';
import useParticipantStatus from '../../Party/hooks/useParticipantStatus';
import SimplePopup from './SimplePopup';

const PartyRejectPopup = ({ state }) => {
  const participantMutation = useParticipantStatus({
    key: ['party', `${state.partyId}`],
    content: `${state.nickName}님의 파티 참여를 거절했어요.`,
  });
  const onClick = () => {
    participantMutation.mutate({ id: state.id, action: 'reject' });
  };
  return (
    <SimplePopup
      title="파티 참여 거절"
      content={`${state.nickName}님의\n파티 참여 신청을 거절할까요?`}
      cancel="취소"
      confirm="거절"
      onClick={onClick}
      color="#ED4956"
    />
  );
};

export default PartyRejectPopup;
