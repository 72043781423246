import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SimplePopup from './SimplePopup';

const LoginWithNoMember = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const pathnameSplit = pathname.split('/');
  const propertyInfo = {
    propertyId: pathnameSplit[2],
    roomtype: pathnameSplit[4],
    rateplan: pathnameSplit[5],
  };
  const { propertyId, roomtype, rateplan } = propertyInfo;

  const onClick = () => {
    navigate('/signIn', {
      state: { from: pathname + search },
    });
  };
  const onCancel = () => {
    navigate(
      `/properties/${propertyId}/booking/${roomtype}/${rateplan}${search}&member=false`
    );
  };
  return (
    <SimplePopup
      title="추가 할인 받기"
      content={
        <>
          <p>로그인 하면 추가 할인을 통해 </p>
          <p>최저가로 예약할 수 있어요!</p>
        </>
      }
      cancel="비회원 예약"
      onCancel={onCancel}
      confirm="간편 로그인"
      onClick={onClick}
    />
  );
};

export default LoginWithNoMember;
