import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import uuid from 'react-uuid';

import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { UserProvider } from './context/auth';
import { PopupProvider } from './context/PopupContext';
import { RecoilRoot } from 'recoil';

const deviceId = window.localStorage.getItem('device-id');

if (!deviceId) {
  window.localStorage.setItem('device-id', uuid());
}

window.getRecentItems = () => {
  return JSON.parse(window.localStorage.getItem('recent_items') || '[]');
};

try {
  window.Kakao.init(process.env.REACT_APP_KAKAO_JS_KEY);
  window.Kakao.isInitialized();
} catch (e) {
  console.error(e);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
      staleTime: 5 * (60 * 1000), // 5 mins
      cacheTime: 10 * (60 * 1000), // 10 mins
    },
    mutations: {},
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <PopupProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </PopupProvider>
    </RecoilRoot>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
