import { useContext, useEffect } from 'react';
import { UserContext } from '../context/auth';
import ChannelService from './ChannelService';

const useChannelService = () => {
  const { user, isLogin } = useContext(UserContext);

  const bootAnnoymous = () => {
    ChannelService.boot({
      pluginKey: '534472f9-471e-4526-a048-fbfa23ecb312',
      customLauncherSelector:
        '.custom-button-1, #custom-button-2, .custom-button-3',
      hideChannelButtonOnBoot: true,
    });
  };

  const bootMember = () => {
    ChannelService.boot({
      pluginKey: '534472f9-471e-4526-a048-fbfa23ecb312',
      customLauncherSelector:
        '.custom-button-1, #custom-button-2, .custom-button-3',
      hideChannelButtonOnBoot: true,
      memberId: user.id,
      profile: {
        sex: user.sex,
        birthday: user.birthday,
        coupon_cnt: user.coupon_cnt,
        point: user.point,
        agree_mkt_push: user.agree_mkt_push,
        agree_mkt_email: user.agree_mkt_email,
        agree_mkt_sms: user.agree_mkt_sms,
        agree_noti_comment: user.agree_noti_comment,
        agree_noti_follow: user.agree_noti_follow,
        agree_noti_like: user.agree_noti_like,
        agree_noti_purchase: user.agree_noti_purchase,
      },
    });
  };

  useEffect(() => {
    if (!isLogin) {
      bootAnnoymous();
    } else {
      bootMember();
    }
  }, [isLogin]);
};

export default useChannelService;
