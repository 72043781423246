import { snackbarState } from 'atoms/atoms';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import useFollow from '../../Profile/hooks/useFollow';
import { MESSAGE } from '../../utils/message';
import SimplePopup from './SimplePopup';

const FollowerPopup = ({ state }) => {
  const openSnackBar = useSetRecoilState(snackbarState);
  const followerMutate = useFollow();
  const onClick = () => {
    followerMutate.mutate(
      { type: true, id: state.id },
      {
        onSuccess: () => {
          openSnackBar({
            open: true,
            content: '팔로우를 취소했습니다.',
          });
        },
      }
    );
  };
  return (
    <SimplePopup
      title={MESSAGE.UN_FOLLOW.TITLE}
      content={state.content}
      cancel="닫기"
      confirm="팔로우 취소"
      onClick={onClick}
    />
  );
};

export default FollowerPopup;
