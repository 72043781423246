import { differenceInHours, format, parseISO } from 'date-fns';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { ko } from 'date-fns/locale';
import { applyForISO } from './utils';

const parseForISO = (date) => {
  return new Date(date.replace(/-/g, '/'));
};

export const setPeriodBasicDate = (date) => {
  return format(parseISO(date), 'MM.dd');
};

export const setPeriodTimeValue = (date) => {
  return format(new Date(date), 'MM/dd(E)', { locale: ko });
};
export const setPeriodTimeValueForParse = (date) => {
  return format(parseISO(date), 'MM/dd(E)', { locale: ko });
};
export const setApplicationDate = (date) => {
  return format(parseForISO(date), 'MM.dd (E)', { locale: ko });
};

export const setPeriodDate = (date) => {
  return format(parseForISO(date), 'MM.dd E', { locale: ko });
};
export const setFullDateFormat = (date) => {
  return format(parseForISO(date), 'yyyy년 M월 d일 HH시');
};

export const setTransactionTime = (date) => {
  return format(parseForISO(date), 'yyyy.MM.dd HH:mm');
};

export const setyyMMdd = (date) => {
  return format(new Date(date), 'yyyy-MM-dd');
};

export const setDateUntilDay = (date) => {
  return format(parseISO(date), 'yyyy.MM.dd (E)', { locale: ko });
};

export const setKoDate = (date) => {
  return format(parseISO(date), 'yyyy년 M월 d일');
};

export const setPointDate = (date) => {
  return format(parseISO(date), 'yy. M. d');
};

export const setDateForTime = (date) => {
  return format(parseISO(date), 'yyyy년 MM월 dd일 HH:mm');
};

export const setCheckDate = (date) => {
  return format(parseISO(date), 'yy. MM. dd (E)', { locale: ko });
};

export const setCheckTime = (date) => {
  return format(parseISO(date), 'HH:mm');
};

export const setDeadline = (date) => {
  return format(parseISO(date), 'MM월 dd일 HH : mm');
};

export const setCheckTimeForInOut = (date) => {
  return format(parseForISO(date), 'HH:mm:ss');
};

export const setNights = (checkin, checkout) => {
  return Math.ceil(
    differenceInHours(parseISO(checkout), parseISO(checkin)) / 24
  );
};

export const setPurchasedDate = (date) => {
  return format(parseForISO(date), 'yyyy.MM.dd (E) HH:mm', { locale: ko });
};

export const compareDate = (date) => {
  const today = new Date();
  const compareDay = applyForISO(date);
  return today < compareDay;
};

export const generateYear = () => {
  const availableYear = new Date().getFullYear() - 14;
  return [...new Array(80)].map((_, i) => availableYear - i);
};

const convertMM = (month) => {
  if (month >= 10) return month;
  return `0${month}`;
};

export const generateMonth = () => {
  return [...new Array(12)].map((_, i) => convertMM(i + 1));
};

export const generateDays = (year, month) => {
  const days = new Date(year, month, 0).getDate();

  return [...new Array(days)].map((_, i) => convertMM(i + 1));
};

export const calendarDate = (date) => {
  return format(date, 'd', { locale: ko });
};

export const compareCalendarDate = (date) => {
  return differenceInCalendarDays(date, new Date());
};

export const compareDays = (start, end) => {
  return differenceInCalendarDays(new Date(start), new Date(end));
};
