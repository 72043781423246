import React, { createContext, useReducer } from 'react';

const UserContext = createContext({
  id: 0,
  name: '여행자',
  nick_name: '여행자',
});

const loginReducer = (userState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...userState,
        ...action.payload,
      };
    case 'LOGOUT':
      return {
        id: 0,
        name: '여행자',
        nick_name: '여행자',
      };
    default:
      return userState;
  }
};

const UserProvider = ({ children }) => {
  const [user, userDispatch] = useReducer(loginReducer, {
    id: 0,
    name: '여행자',
    nick_name: '여행자',
  });
  const store = {
    user,
    userDispatch,
    isLogin: user.id > 0,
  };
  return <UserContext.Provider value={store}>{children}</UserContext.Provider>;
};

export { UserProvider, UserContext };
