/* eslint-disable react/react-in-jsx-scope */
export const MESSAGE = {
  MYPAGE: {
    MOMENT_EMPTY: '회원님의 소중한 모먼트를 기록해보세요 📽',
    MOMENT_EMPTY_COUPEN: '업로드 할 때 마다 쌓이는 쏠쏠한 포인트 🥳',
    MOMENT_BUTTON: '모먼트 기록하기',
    MOMENT_NAVI: '/moments',
    NOT_LOGIN: '로그인이 필요해요',
    NOT_LOGIN_TWO: '회원님의 소중한 시간, 3초면 돼요',
    LOGIN_BUTTON: '로그인하기',
    SIGNIN_NAVI: '/signIn',
  },
  EMPTY: {
    ALRAM: '새 알림이 없어요.',
  },
  MOMENT_POPUP: {
    TITLE: '모바일 앱 전용 기능이에요!',
    CONTENT: '앱에서 사용하시겠어요?',
    BUTTON: '앱으로 가기',
  },
  MOMENT_REPORT_COMPLETE: {
    TITLE: '신고가 접수되었어요 🚩',
    CONTENT: `많이 놀라셨죠? 대신 사과드릴게요 😥\n체크인 규정을 위반한 모먼트라면 곧 삭제될 거에요.`,
  },
  RESERVATION: {
    EMPTY: '예약한 숙소내역이 없네요 🥺',
    EMPTY_TWO: '추천 숙소 둘러보고 체크인해보세요!',
    BUTTON: '숙소 둘러보기',
    NAVI: '/',
  },
  EMPTY_WISH_LSIT: {
    CONTENT: '회원님의 마음에 드는 숙소를 위시리스트에 모아보세요. 😉',
    BUTTON: '숙소 둘러보기',
  },
  EMPTY_POINT: {
    TITLE: '아직 포인트가 없네요 😦',
    CONTENT: '체크인에서 숙소 예약 후 \n 모먼트를 남기시면 포인트를 드려요!',
    BUTTON: '홈으로 가기',
  },
  LOGIN: {
    TITLE: '로그인이 필요한 기능이에요',
    CONTENT: '지금 회원가입하시고 10만원 쿠폰팩 등 풍성한 혜택을 받아 보세요!',
  },
  LOGOUT: {
    TITLE: '정말 로그아웃 하시겠어요?',
  },
  WITHDRAW: {
    TITLE: '회원 탈퇴',
    CONTENT:
      '그동안 감사했어요. \n 더 나은 체크인이 될테니 꼭 \n 다시 찾아주세요!',
  },
  COMPLETE_WITHDRAW: {
    TITLE: '회원 탈퇴 완료 ✨',
    CONTENT: '님,\n 그동안 함꼐해서 즐거웠어요. \n 다시 만나는 그날까지 안녕!',
    BUTTON: '잘 있어요, 체크인!',
  },
  POINT: {
    TITLE: '체크인 포인트',
    CONTENT:
      '유효기간이 지난 포인트는 \n 자동으로 소멸됩니다. \n 지급된 포인트의 유효기간은 적립 종류에 따라 달라질 수 있습니다.',
  },
  UN_FOLLOW: {
    TITLE: '팔로우 취소',
    CONTENT: '님을 팔로우 취소하시겠어요?',
  },
  BLOKED: {
    TITLE: '앗, 차단된 유저에요🤔',
    CONTENT:
      '차단을 해제하시면 상대 회원님께서 다시 회원님의\n 프로필, 모먼트 및 기타 활동 내역을 볼 수 있어요.',
    BUTTON: '차단 해제',
  },
  NO_FOLLOWER: {
    CONTENT: '님은 아직 아무도 \n 팔로우하고 있지 않네요 🥺',
  },
  NO_FOLLOWING: {
    CONTENT: '님의 \n 첫 번째 팔로워가 되어보세요. 😉',
  },
  NO_FOLLOWER_ME: {
    TITLE: '나를 팔로우 한 사람이 없어요.',
    CONTENT:
      '나를 팔로우하는 모든 사람들을 볼 수 있어요. \n 모먼트를 올려 팔로워를 모아보세요!',
  },
  NO_FOLLOWING_ME: {
    CONTENT: '마음에 드는 모먼트 \n 크리에이터를 팔로우해보세요. 😉',
  },
  PRE_USER: {
    TITLE: '앗, 이미 가입하신 계정이 있어요 💙',
    CONTENT:
      '휴대폰 번호 1개당\n하나의 계정만 생성할 수 있어요.\n이전에 가입하신 계정으로 로그인 부탁드려요.',
    BUTTON: '다른 계정으로 로그인',
  },
  REFUND_POLICY: [
    '• 입실 시간이 경과된 경우, 예약완료 직후여도 취소 및 환불이 불가합니다.',
    '• 예약/결제 당시에 적용된 판매가와 해당 상품의 취소/환불 규정에 따라 취소 수수료가 계산됩니다.',
    '• 숙소 사정에 의한 취소 시 100% 환불 가능합니다.',
    '• 취소 수수료 발생 시, 쿠폰 및 포인트와 같은 할인금액을 포함한 최초 예약 금액을 기준으로 계산됩니다.',
    '• 미성년자 혼숙으로 인해 발생하는 입실 거부에 대해서는 취소 및 환불이 불가능합니다.',
    '• 숙소 사정으로 객실 정보가 수시로 변경될 수 있습니다. 이로 인한 불이익은 당사가 책임지지 않습니다.',
  ],
  PROPERTY_REFUND_POLICY: [
    '- 입실 시간이 경과된 경우, 예약완료 직후여도 취소 및 환불이 불가합니다.',
    '- 예약/결제 당시에 적용된 판매가와 해당 상품의 취소/환불 규정에 따라 취소 수수료가 계산됩니다.',
    '- 숙소 사정에 의한 취소 시 100% 환불 가능합니다.',
    '- 취소 수수료 발생 시, 쿠폰 및 포인트와 같은 할인금액을 포함한 최초 예약 금액을 기준으로 계산됩니다.',
    '- 미성년자 혼숙으로 인해 발생하는 입실 거부에 대해서는 취소 및 환불이 불가능합니다.',
    '- 숙소 사정으로 객실 정보가 수시로 변경될 수 있습니다. 이로 인한 불이익은 당사가 책임지지 않습니다.',
  ],
  REFUND_POLICY_PARTY: [
    `해당 예약은 ‘1/N 파티’ 기능을 통해 특별 할인된 가격으로, 멤버 모집 및 결제가 완료되어 파티 예약이 확정된 이후에는 원칙적으로 취소 및 환불이 불가능합니다.`,
    '예약 확정 이후 정상참작이 가능한 사유(천재지변 및 숙소 사정 등)로 취소를 해야할 경우, 앱 내 고객센터(마이페이지 > FAQ > 문의하기)로 문의 부탁드립니다.',
  ],
  PARTT_CANCEL_POPUP: {
    TITLE: '앗, 잠깐만요 😣',
    CONTENT:
      '대기를 취소하면 다시 되돌릴 수 없어요.\n정말 취소하시겠어요?\n취소 시 참가비는 전액 환불됩니다.',
    BUTTON: '대기 취소',
  },
  PARTT_CANCEL_JOIN_POPUP: {
    TITLE: '앗, 잠깐만요 😣',
    CONTENT:
      '참여를 취소하면 다시 되돌릴 수 없어요.\n정말 취소하시겠어요?\n취소 시 참가비는 전액 환불됩니다.',
    BUTTON: '참여 취소',
  },
  PARTT_DELETE_POPUP: {
    TITLE: '파티 내역 삭제',
    CONTENT: '삭제한 내역은 다시 복구할 수 없어요. 정말 삭제하시겠어요?',
    BUTTON: '삭제',
  },
  PARTT_CANCEL_OPEN_POPUP: {
    TITLE: '앗, 잠깐만요 😣',
    CONTENT:
      '오픈을 취소하면 다시 되돌릴 수 없어요.\n정말 파티를 취소하시겠어요?',
    BUTTON: '오픈 취소',
  },

  PARTY_EMPTY: {
    TITLE: {
      WAITING: '대기 중인 파티 내역이 없어요 🥺',
      JOIN: '참여 중인 파티 내역이 없어요 🥺',
      HOST: '오픈된 파티 내역이 없어요 🥺',
      CANCEL: '취소된 파티 내역이 없어요 🥺',
    },
    CONTENT: '새로 열린 파티에 참여 신청해보세요!',
    CONTENT_OPEN: '가고 싶은 파티를 직접 만들어보세요!',
    BUTTON: '파티 둘러보기',
    BUTTON_OPEN: '파티 오픈하기',
  },
  PARTY_MEMBER_EMPTY: {
    TITLE: '아직 참여 중인 멤버가 없어요 😖',
    CONTENT: '함께하고 싶은 친구들에게 파티를 공유해보세요!',
    BUTTON: '친구 초대하기',
  },
  DUTCH_PAY_CUMMISSION: [
    {
      id: 1,
      value: (
        <span>
          결제 마감 시간까지 예약이 확정되지 않을 경우, 결제 자동 취소 안내와
          함께 결제하신 금액은 전액 환불해 드립니다.
        </span>
      ),
    },
    {
      id: 2,
      value: (
        <span>
          <strong>
            결제 마감 시간 이전에는 언제든 100% 취소가 가능합니다.
          </strong>
        </span>
      ),
    },
    {
      id: 3,
      value: (
        <span>
          숙소 사정으로 객실 정보가 수시로 변경될 수 있습니다.{' '}
          <strong>
            결제 완료가 늦어질 경우 숙소의 가격 및 재고 변동으로 인해 예약이
            취소될 수 있으니, 가능한 빨리 결제를 완료해주세요.
          </strong>
        </span>
      ),
    },
    {
      id: 4,
      value: <span>숙소 사정에 의한 취소 시 100% 환불 가능합니다.</span>,
    },
    {
      id: 5,
      value: (
        <span>
          예약 확정 이후에 숙소 예약을 취소할 시, 취소 시점에서 발생하는
          수수료는 각 인원이 결제한 금액의 비율에 따라 수수료를 제하고
          환불해드립니다.
        </span>
      ),
    },
    {
      id: 6,
      value: (
        <span>
          예약 확정 당시에 적용된 판매가와 해당 상품의 취소/환불 규정에 따라
          취소 수수료가 계산됩니다.
        </span>
      ),
    },
  ],
  DUTCH_PAY_NO_CUMMISSION: [
    {
      id: 1,
      value: (
        <span>
          결제 마감 시간까지 예약이 확정되지 않을 경우, 결제 자동 취소 안내와
          함께 결제하신 금액은 전액 환불해 드립니다.
        </span>
      ),
    },
    {
      id: 2,
      value: (
        <span>
          <strong>
            결제 마감 시간 이전에는 언제든 100% 취소가 가능합니다.
          </strong>
        </span>
      ),
    },
    {
      id: 3,
      value: (
        <span>
          예약 확정 이후에 숙소 예약을 취소할 시, 취소 시점에서 발생하는
          수수료는 각 인원이 결제한 금액의 비율에 따라 수수료를 제하고
          환불해드립니다.
        </span>
      ),
    },
    {
      id: 4,
      value: <span>숙소 사정에 의한 취소 시 100% 환불 가능합니다.</span>,
    },
    {
      id: 5,
      value: (
        <span>
          예약 확정 당시에 적용된 판매가와 해당 상품의 취소/환불 규정에 따라
          취소 수수료가 계산됩니다.
        </span>
      ),
    },
    {
      id: 6,
      value: (
        <span>
          숙소 사정으로 객실 정보가 수시로 변경될 수 있습니다. 이로 인한
          불이익은 당사가 책임지지 않습니다.
        </span>
      ),
    },
  ],
  CANCEL_NOTICE: [
    '예약/결제 당시에 적용된 판매가와 해당 상품의 취소/환불 규정에 따라 취소 수수료가 계산됩니다.',
    '취소 수수료가 발생한 경우 포인트 -> 현금 순으로 차감됩니다. 실결제금액에서 현금에서 차감되는 취소 수수료를 뺀 금액이 결제 수단으로 환불 됩니다.',
    '예약 취소 시, 사용한 쿠폰은 즉시 복원됩니다. 쿠폰은 복원 여부에 상관없이 쿠폰의 본래 유효기간에 따라 자동으로 삭제될 수 있으니 주의 바랍니다.',
    '예약 취소 시, 사용한 포인트는 포인트로 복원됩니다. 단, 취소 수수료를 차감한 금액으로 복원될 수 있습니다. 포인트는 취소에 의한 복원 여부에 상관없이 원 유효기간 등에 따라 잔액이 소멸될 수 있으니 주의 바랍니다.',
    '호텔 상품의 경우, 재고/가격 조회 할 때의 취소/환불 수수료 정책과 실제 예약된 건의 취소/환불 수수료 정책이 다를 수 있습니다. 실제 예약된 건의 취소/환불 수수료가 최종 확정 정책입니다.',
    '결제 수단이 휴대폰결제인 경우 취소 시점에 따라 취소 수수료가 재승인 될 수 있습니다.',
    '휴대폰 전월 결제 건은 예약 취소 후 예약자 본인 명의의 통장으로 환불처리 됩니다.',
    '환불은 예약 취소 후 PG사 승인 취소기간에 따라 주말, 공휴일을 제외한 영업일 기준 최대 3-5일 소요됩니다.',
  ],
};
