import React from 'react';
import useParticipantStatus from '../../Party/hooks/useParticipantStatus';
import SimplePopup from './SimplePopup';

const PartyAgreePopup = ({ state }) => {
  const participantMutation = useParticipantStatus({
    key: ['party', `${state.partyId}`],
    content: `${state.nickName}님의 파티 참여를 수락했어요.`,
    state: {
      ...state,
    },
  });
  const onClick = () => {
    participantMutation.mutate({ id: state.id, action: 'allow' });
  };
  return (
    <SimplePopup
      title="파티 참여 수락"
      content={`${state.nickName}님을\n파티 메이트로 수락할까요?`}
      cancel="취소"
      confirm="수락"
      onClick={onClick}
    />
  );
};

export default PartyAgreePopup;
