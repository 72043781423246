import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../../API';

const useFollow = () => {
  const queryClient = useQueryClient();
  const fetchApi = async ({ type, id }) => {
    return type
      ? await API.delete(`account/${id}/followers`)
      : await API.post(`account/${id}/followers`);
  };
  return useMutation(fetchApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(['account']);
      queryClient.invalidateQueries(['followings']);
      queryClient.invalidateQueries(['followers']);
    },
  });
};

export default useFollow;
