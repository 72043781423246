import React from 'react';
import { isDesktop } from 'react-device-detect';
import { MESSAGE } from '../../utils/message';
import SimplePopup from './SimplePopup';

const MomentPopup = () => {
  const onClick = () => {
    window.open('https://applink.checkinn.kr/main');
  };
  if (isDesktop)
    return (
      <SimplePopup
        title={MESSAGE.MOMENT_POPUP.TITLE}
        content="데스크탑에서는 사용할 수 없어요. 앱에서 사용해주세요😉"
        confirm="확인"
      />
    );
  return (
    <SimplePopup
      title={MESSAGE.MOMENT_POPUP.TITLE}
      content={MESSAGE.MOMENT_POPUP.CONTENT}
      confirm={MESSAGE.MOMENT_POPUP.BUTTON}
      cancel="취소"
      onClick={onClick}
    />
  );
};

export default MomentPopup;
