import React from 'react';
import { useRecoilValue } from 'recoil';
import qs from 'qs';
import styled from 'styled-components';
import { differenceInDays } from 'date-fns';
import { bookingRefundTitle } from 'atoms/atoms';
import SimplePopup from './SimplePopup';
import { setDateUntilDay } from 'utils/date';
import { Col, Row } from 'styles/Layout';

const Content = ({ state }) => {
  const refundTitle = useRecoilValue(bookingRefundTitle);
  const parameters = qs.parse(location.search.substr(1));

  return (
    <Container>
      <Col>
        <PropertyName>{state.propertyName}</PropertyName>
        <Rommtype>
          {state.roomtype} /{' '}
          {differenceInDays(
            new Date(parameters.to_date),
            new Date(parameters.from_date)
          )}
          박
        </Rommtype>
      </Col>
      <Col>
        <Row gap="10">
          <Span>체크인</Span>
          <CheckinOut>
            {setDateUntilDay(parameters.from_date)} {state.checkin.substr(0, 5)}
          </CheckinOut>
        </Row>
        <Row gap="10">
          <Span>체크아웃</Span>
          <CheckinOut>
            {setDateUntilDay(parameters.to_date)} {state.checkout.substr(0, 5)}
          </CheckinOut>
        </Row>
      </Col>
      <span className={`refund-policy ${refundTitle.color}`}>
        {refundTitle.title}
      </span>
    </Container>
  );
};

const PropertyBookingPopup = ({ state }) => {
  const onSubmit = async () => {
    await state.onPay();
  };

  return (
    <SimplePopup
      title="예약내역 확인"
      confirm="동의 후 결제"
      content={<Content state={state} />}
      onClick={onSubmit}
      cancel="취소"
    />
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
`;

const PropertyName = styled.h3`
  font-size: var(--theme-text-m);
  color: var(--theme-text-primary);
`;

const Rommtype = styled.span`
  font-size: var(--theme-text-m);
  color: var(--theme-text-secondary);
`;

const Span = styled.span`
  font-size: var(--theme-text-s);
  color: var(--theme-text-secondary);
`;

const CheckinOut = styled.span`
  font-size: var(--theme-text-s);
  color: var(--theme-text-primary);
`;

export default PropertyBookingPopup;
