import React, { useEffect } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import { snackbarState } from '../atoms/atoms';
import { overlay, overlayOff } from './botomsheet/styles/BottomSheet.style';
import ReactPortal from './portal/ReactPortal';

const DetailAlert = () => {
  const [snackbar, setSnackbar] = useRecoilState(snackbarState);

  const resetSnackbar = useResetRecoilState(snackbarState);

  useEffect(() => {
    if (snackbar.open) {
      setTimeout(() => {
        setSnackbar({ ...snackbar, open: false });
        setTimeout(() => {
          resetSnackbar();
        }, [300]);
      }, 2000);
    }
  }, [resetSnackbar, setSnackbar, snackbar, snackbar.open]);

  if (!snackbar.content) return null;

  return (
    <ReactPortal overflow={false} wrapperId="react-portal-snackbar">
      <Alert animate={snackbar.open}>
        <Message>{snackbar.content}</Message>
      </Alert>
    </ReactPortal>
  );
};

const Alert = styled.div`
  position: fixed;
  bottom: 107px;
  left: 0;
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.animate
      ? css`
          animation: ${overlay} 0.2s 0s forwards;
        `
      : css`
          animation: ${overlayOff} 0.2s 0s forwards;
        `};
`;

const Message = styled.p`
  padding: 0 10px;
  display: flex;
  min-width: 300px;
  width: auto;
  height: 40px;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 141, 0.9);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  line-height: 17px;
  font-size: 14px;
  color: var(--theme-text-primary);
`;

export default React.memo(DetailAlert);
