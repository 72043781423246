import React from 'react';
import SimplePopup from './SimplePopup';

const ReservationWithdrawReasonPopup = () => {
  return (
    <SimplePopup
      title="앗, 취소 사유를 알려주세요 😣"
      content={`취소 사유를 선택해 주신다면\n더 나은 체크인 문화를 만들수 있어요!`}
    />
  );
};

export default ReservationWithdrawReasonPopup;
