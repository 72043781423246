import React, { useEffect, useRef } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { popupState } from '../../atoms/atoms';
import ReactPortal from '../portal/ReactPortal';
import {
  Container,
  PopupBox,
  PopupBtn,
  PopupBtns,
  PopupComfirm,
  PopupContent,
  PopupTitle,
} from './styles';

const SimplePopup = ({
  title,
  content,
  cancel,
  confirm = '확인',
  onClick,
  onCancel,
  color,
}) => {
  const [popup, setPopup] = useRecoilState(popupState);
  const resetPopup = useResetRecoilState(popupState);

  const ref = useRef();

  const closePopup = () => {
    setPopup({ ...popup, open: false });
    setTimeout(() => {
      resetPopup();
    }, 300);
  };

  const setRef = (node) => {
    if (ref.current) {
      ref.current = null;
      return;
    }
    if (node) {
      ref.current = node;
      document.addEventListener('mousedown', (e) => {
        if (popup.open) {
          if (ref.current && !ref.current.contains(e.target)) closePopup();
        }
      });
    }
  };

  useEffect(() => {});
  return (
    <ReactPortal wrapperId="react-portal-modal">
      <Container isShowing={popup.open}>
        <PopupBox role="dialog" aria-label aria-modal="true" ref={setRef}>
          <PopupTitle>{title}</PopupTitle>
          <PopupContent>{content}</PopupContent>
          <PopupBtns>
            {cancel && (
              <PopupBtn
                onClick={() => {
                  onCancel && onCancel();
                  closePopup();
                }}
              >
                {cancel}
              </PopupBtn>
            )}

            <PopupComfirm
              color={color}
              onClick={() => {
                onClick && onClick();
                closePopup();
              }}
            >
              {confirm}
            </PopupComfirm>
          </PopupBtns>
        </PopupBox>
      </Container>
    </ReactPortal>
  );
};

export default React.memo(SimplePopup);
