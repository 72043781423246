import React from 'react';
import { nf } from '../../functions';
import SimplePopup from './SimplePopup';

const ReservationWithdrawPopup = ({ state }) => {
  return (
    <SimplePopup
      title="숙박 예약을 취소할까요?"
      content={`환불 예정 금액은 ${nf(
        state.refundPrice
      )}원이에요.\n예약 취소 전 취소 및 환불 규정을\n꼭 확인해 주세요. `}
      cancel="그냥 냅둘래요"
      confirm="네, 취소할게요"
      onClick={state.onClick}
    />
  );
};

export default ReservationWithdrawPopup;
