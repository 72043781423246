import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';
import styled, { css } from 'styled-components';
import ReactPortal from 'components/portal/ReactPortal';
import {
  slideDown,
  slideUp,
} from 'components/botomsheet/styles/BottomSheet.style';
import Icon from 'components/atoms/Icon';
import { ICON } from 'utils/image';
import useFullScreenClose from './hooks';
import { useRecoilState } from 'recoil';
import { fullScreenState } from 'atoms/atoms';

const SimpleFullscreen = ({
  children,
  title,
  background = '#ffffff',
  titleNo = false,
}) => {
  const [fullscreenValue, setFullscreenValue] = useRecoilState(fullScreenState);
  const { onClickClose } = useFullScreenClose();
  const location = useLocation();
  const { modal } = parse(location.search.substr(1));

  useEffect(() => {
    if (!modal) {
      onClickClose({ nav: false });
    } else {
      if (!fullscreenValue.open) {
        setFullscreenValue((cur) => ({ ...cur, open: true, type: modal }));
      }
    }
  }, [modal]);

  return (
    <ReactPortal wrapperId="react-portal-fullscreen-modal">
      <Modal isShowing={fullscreenValue.open} background={background}>
        <Container>
          <Header>
            {background === '#ffffff' ? (
              <Icon
                onClick={onClickClose}
                src={ICON.CLOSE.url}
                alt={ICON.CLOSE.alt}
              />
            ) : (
              <Icon
                onClick={onClickClose}
                src={ICON.CLOSE_WHITE.url}
                alt={ICON.CLOSE_WHITE.alt}
              />
            )}

            <Title titleNo={titleNo} background={background}>
              {title}
            </Title>

            <Box />
          </Header>
          <Content className="scroll-none">{children}</Content>
        </Container>
      </Modal>
    </ReactPortal>
  );
};

const Modal = styled.div`
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  max-width: 500px;
  min-height: calc(var(--vh, 1vh) * 100);
  height: 100%;

  background: ${(props) => props.background};
  ${(props) =>
    props.isShowing
      ? css`
          animation: ${slideUp} 0.4s 0s forwards;
        `
      : css`
          animation: ${slideDown} 0.3s 0s forwards;
        `};
`;

const Title = styled.h2`
  font-size: var(--theme-text-xl);
  color: ${(props) => (props.background === '#ffffff' ? '#463E3F' : '#ffffff')};

  ${(props) =>
    props.titleNo &&
    css`
      font-weight: 400;
      font-size: 14px;
    `};
`;

const Container = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 10px;
`;

const Box = styled.div`
  width: 24px;
  height: 50px;
`;

const Content = styled.section`
  height: 100%;
  overflow-y: scroll;
`;

export default SimpleFullscreen;
