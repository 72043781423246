import React from 'react';
import useParticipantStatus from '../../Party/hooks/useParticipantStatus';
import SimplePopup from './SimplePopup';

const PartyRejectJoinPopup = ({ state }) => {
  const participantMutation = useParticipantStatus({
    key: ['party', `${state.partyId}`],
    content: `${state.nickName}님을 파티에서 내보냈어요.`,
  });
  const onClick = () => {
    participantMutation.mutate({ id: state.id, action: 'reject' });
  };
  return (
    <SimplePopup
      title="앗, 잠깐만요"
      content={`파티 맴버를 내보내면 다시 메이트를\n모집해야 해요. 정말 내보내시겠어요?`}
      cancel="취소"
      confirm="확인"
      onClick={onClick}
    />
  );
};

export default PartyRejectJoinPopup;
