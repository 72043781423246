import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../../API';

const useDelReservation = () => {
  const queryClient = useQueryClient();
  const fetchApi = async ({ id }) => {
    await API.delete(`reservation/${id}`);
  };
  return useMutation(fetchApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(['activity', 'property']);
    },
  });
};

export default useDelReservation;
