import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../../API';
import { bottomSheetState, snackbarState } from '../../atoms/atoms';
import { useSetRecoilState } from 'recoil';
import { AxiosError } from 'axios';

const useParticipantStatus = ({ key, content, state = null }) => {
  const queryClient = useQueryClient();
  const setSnackbar = useSetRecoilState(snackbarState);
  const setBottomSheet = useSetRecoilState(bottomSheetState);
  const fetchApi = async ({ id, action }) => {
    await API.put(`/party/participant/${id}/status?action=${action}`);
  };
  return useMutation(fetchApi, {
    onSuccess: () => {
      setSnackbar({ open: true, content });
      return queryClient.invalidateQueries(key);
    },
    onError: (e) => {
      if (e instanceof AxiosError) {
        if (e.response.data.error.code === 400) {
          setSnackbar({ open: true, content: '이미 취소한 파티입니다.' });
          return queryClient.invalidateQueries(key);
        } else if (e.response.data.error.code === 4001) {
          setBottomSheet({
            type: 'PARTY_REJECT_NOT_POSSIBLE',
            open: true,
            state: { ...state },
          });
        }
      }
    },
  });
};

export default useParticipantStatus;
