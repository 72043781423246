import React from 'react';
import styled from 'styled-components';
import SimplePopup from './SimplePopup';

const ReservationTooltipPopup = ({ state }) => {
  const Content = () => {
    return state.refundable ? (
      <ul>
        <NotRefundable>
          숙소 규정 및 취소 요청 시각에 따라 환불 금액이 달라질 수 있어요.
        </NotRefundable>
      </ul>
    ) : (
      <ul>
        <NotRefundable>구매 시 환불 불가 상품인 경우</NotRefundable>
        <NotRefundable>
          숙소 환불 규정에 따라 환불 가능한 시간이 지난 경우
        </NotRefundable>
        <NotRefundable>환불 금액이 실 결제 금액보다 큰 경우</NotRefundable>
      </ul>
    );
  };
  return <SimplePopup title="취소 및 환불 불가 숙소" content={<Content />} />;
};

const NotRefundable = styled.li`
  font-size: var(--theme-text-xs);
  line-height: 18px;
  color: var(--theme-text-primary);
  text-align: left;
  position: relative;
  padding-left: 10px;
  &::before {
    content: '';
    width: 3px;
    height: 3px;
    background: var(--theme-text-primary);
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 7px;
  }
`;

export default ReservationTooltipPopup;
