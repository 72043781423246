import React from 'react';
import { useLocation } from 'react-router-dom';
import { IMG } from '../../utils/image';
import AppMovePopup from './AppMovePopup';

const FirstVisitPopup = () => {
  const { pathname } = useLocation();

  if (pathname !== '/') {
    return null;
  }
  // 네이버를 통해서 접속한 경우 유도 팝업을 띄우지 않는다.

  return (
    <AppMovePopup
      title={`앱에서만 제공되는\n다양한 혜택을 놓치지 마세요!`}
      notice="쉽고 빠른 1/N 결제, 불법카메라 AI 탐지 기능까지!"
      confrim="앱 이용하기"
      disagree="오늘은 웹으로 볼게요."
      src={IMG.FIRST_VISIT.url}
      session={true}
    />
  );
};

export default FirstVisitPopup;
