import { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../../API';
import { UserContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';

const useWithdraw = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { userDispatch } = useContext(UserContext);
  const fetchApi = async ({ reasons, description }) => {
    await API.delete('account/me', {
      reasons: [reasons],
      description,
    });
  };
  return useMutation(fetchApi, {
    onSuccess: () => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      userDispatch({ type: 'LOGOUT' });
      queryClient.invalidateQueries(['account']);
      queryClient.invalidateQueries(['home', 'title']);
      navigate('/', { replace: true });
    },
  });
};

export default useWithdraw;
