import { useQuery } from '@tanstack/react-query';
import API from 'API';
import qs from 'qs';

const useUseable = ({ price }) => {
  const fetchApi = async () => {
    const { data } = await API.get(
      `coupons/usable?` +
        qs.stringify({
          price,
        })
    );
    return data;
  };
  return useQuery(['coupon', 'useable'], fetchApi, {
    suspense: true,
    useErrorBoundary: true,
    enabled: Boolean(price),
  });
};

export default useUseable;
