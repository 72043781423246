import styled, { css } from 'styled-components';
import { overlay, overlayOff } from '../../botomsheet/styles/BottomSheet.style';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 101;
  transform: translateX(-50%);
  ${(props) =>
    props.isShowing
      ? css`
          animation: ${overlay} 0.2s 0s forwards;
        `
      : css`
          animation: ${overlayOff} 0.2s 0s forwards;
        `};
`;

export const PopupBox = styled.section`
  width: 80vw;
  max-width: 270px;
  background: #ffffff;
  border-radius: 20px 20px 0px 20px;
  padding-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  gap: 16px;
  position: relative;
  text-align: center;
`;

export const PopupTitle = styled.h2`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #171717;
  padding: 0 16px;
`;

export const PopupContent = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #171717;
  padding: 0 16px;
  white-space: pre-line;
  word-break: break-all;
`;

export const PopupBtns = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #f1f1f1;
`;

export const PopupBtn = styled.button`
  flex: 1;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  background: transparent;
  color: var(--theme-text-secondary);
  padding: 13px 0;
  border-right: 1px solid #f1f1f1;
`;

export const PopupComfirm = styled(PopupBtn)`
  color: var(--theme-blue-primary);
  color: ${(props) => props.color && props.color};
`;
