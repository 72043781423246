import React from 'react';
import { BounceLoader } from 'react-spinners';
import styled from 'styled-components';

const Loader = () => {
  return (
    <Container>
      <BounceLoader color="#0080FF" />
    </Container>
  );
};

const Container = styled.div`
  height: calc(var(--vh, 1vh) * 70);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export default Loader;
