import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../../API';
import { snackbarState } from '../../atoms/atoms';
import { useSetRecoilState } from 'recoil';

const useDelParty = () => {
  const setSnackbar = useSetRecoilState(snackbarState);
  const queryClient = useQueryClient();

  const fetchApi = async ({ id }) => {
    await API.delete(`party/participant/${id}`);
  };
  return useMutation(fetchApi, {
    onSuccess: () => {
      setSnackbar({ open: true, content: '파티 내역을 삭제했어요.' });
      return queryClient.invalidateQueries(['activity', 'party']);
    },
  });
};

export default useDelParty;
