import React from 'react';
import styled from 'styled-components';

const Icon = ({
  src,
  alt,
  onClick,
  after,
  sizes,
  width,
  height,
  padding = null,
}) => {
  return (
    <Container after={after} padding={padding} onClick={onClick}>
      <Img
        width={width}
        height={height}
        after={after}
        src={src}
        alt={alt}
        sizes={sizes}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => props.padding && `padding: ${props.padding}`};

  ${(props) =>
    props.after &&
    `&::after {
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: var(--theme-red-primary);
    position: absolute;
    top: 0;
  }`}
`;
const Img = styled.img`
  cursor: pointer;
  ${(props) =>
    props.sizes &&
    `
  width: ${props.sizes}px;
  height: ${props.sizes}px;`}
`;

export default Icon;
