import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const API = axios.create({
  baseURL: 'https://stay-api.spresto.net/v2/', // 개발
  // baseURL: 'https://dev-stay-api.spresto.net/v2/', // 테스트
  // baseURL: 'https://stay-batch.spresto.net/v2/', // 로컬
  timeout: 100000,
});

export const API_WITHOUT_TOKEN = axios.create({
  baseURL: 'https://stay-api.spresto.net/v2/', // 개발
  // baseURL: 'https://dev-stay-api.spresto.net/v2/', // 테스트
  // baseURL: 'https://stay-batch.spresto.net/v2/', // 로컬
  timeout: 100000,
});

const checkWebview = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return (
    userAgent.includes('checkin_app_aos') ||
    userAgent.includes('checkin_app_ios')
  );
};

function getAccessToken() {
  if (checkWebview()) {
    return document.getElementById('auth-token').value;
  }
  return localStorage.getItem('access_token') ?? null;
}

function getRefreshToken() {
  return localStorage.getItem('refresh_token');
}

const refreshAuthLogic = () => {
  API.put('auth', {
    refresh_token: getRefreshToken(),
  }).then((tokenRefreshResponse) => {
    localStorage.setItem(
      'access_token',
      tokenRefreshResponse.data.access_token
    );
  });
};
createAuthRefreshInterceptor(API, refreshAuthLogic);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.request.use(
  (request) => {
    request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
    request.headers['device-id'] = localStorage.getItem('device-id') || '';
    request.headers['os'] = 'w';
    request.headers['TZ'] = new Date().getTimezoneOffset();
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API_WITHOUT_TOKEN.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
API_WITHOUT_TOKEN.interceptors.request.use(
  (request) => {
    request.headers['device-id'] = localStorage.getItem('device-id') || '';
    request.headers['os'] = 'w';
    request.headers['TZ'] = new Date().getTimezoneOffset();
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.getItem = async (id, capacity) => {
  let resp;

  try {
    resp = await API.get(`property/${id}?capacity=${capacity}`);
  } catch (err) {
    console.error(err);
  }

  return resp.data;
};

export default API;
