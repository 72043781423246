export const POPUP_TYPE = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  WITHDRAW: 'WITHDRAW',
  COMPLETE_WITHDRAW: 'COMPLETE_WITHDRAW',
  POINT: 'POINT',
  PRE_AUTH: 'PRE_AUTH',
  FOLLOWER: 'FOLLOWER',
  MOMENT: 'MOMENT',
  PARTY_CANCEL_WAITING: 'PARTY_CANCEL_WAITING',
  PARTY_CANCEL_JOIN: 'PARTY_CANCEL_JOIN',
  PARTY_DELETE: 'PARTY_DELETE',
  PARTY_CANCEL_OPEN: 'PARTY_CANCEL_OPEN',
  PARTY_AGREE: 'PARTY_AGREE',
  PARTY_REJECT: 'PARTY_REJECT',
  PARTY_REJECT_JOIN: 'PARTY_REJECT_JOIN',
  TOOLTIP_RESERVATION_DETAIL: 'TOOLTIP_RESERVATION_DETAIL',
  SIGNUP_ERROR: 'SIGNUP_ERROR',
  RESERVATION_WITHDRAW: 'RESERVATION_WITHDRAW',
  RESERVATION_WITHDRAW_REASON: 'RESERVATION_WITHDRAW_REASON',
  RESERVATION_DELETE: 'RESERVATION_DELETE',
  CHECK_RESERVATION: 'CHECK_RESERVATION',
  BASIC: 'BASIC',
  FIRST_VISIT: 'FIRST_VISIT',
  DUTCH_FOR_MOBILE: 'DUTCH_FOR_MOBILE',
  PROPERTY_BOOKING: 'PROPERTY_BOOKING',
  RESERVATION_NO_MEMBER: 'RESERVATION_NO_MEMBER',
};

export const SHEET_TYPE = {
  BASIC: 'BASIC',
  REFUND: 'REFUND',
  BOOKING: 'BOOKING',
  PARTY_CANCEL_JOIN_FULL: 'PARTY_CANCEL_JOIN_FULL',
  PARTY_CANCEL_OPEN_MORE: 'PARTY_CANCEL_OPEN_MORE',
  UTIL: 'UTIL',
  DECLARATION: 'DECLARATION',
  BLOCKING: 'BLOCKING',
  PARTY_REJECT_JOIN: 'PARTY_REJECT_JOIN',
  PARTY_CONFIRMED: 'PARTY_CONFIRMED',
  PARTY_REJECT_NOT_POSSIBLE: 'PARTY_REJECT_NOT_POSSIBLE',
  PARTY_AFTER_REVIEW: 'PARTY_AFTER_REVIEW',
  RESERVATION_MORE: 'RESERVATION_MORE',
  WITHDRAW_REASONS: 'WITHDRAW_REASONS',
  PERIOD_SELECTOR: 'PERIOD_SELECTOR',
  PERIOD_PRICE_SELECTOR: 'PERIOD_PRICE_SELECTOR',
  CAPACITY_SELECTOR: 'CAPACITY_SELECTOR',
  REGION_SELECTOR: 'REGION_SELECTOR',
  SORTING_SELECTOR: 'SORTING_SELECTOR',
  CATEGORY_SELECTOR: 'CATEGORY_SELECTOR',
  MOMENT_COMMENT: 'MOMENT_COMMENT',
};

export const FULLSCREEN = {
  SIMPLE: 'SIMPLE',
  PROPERTY_MAP: 'PROPERTY_MAP',
  PROPERTY_IMG: 'PROPERTY_IMG',
  COUPON_SELECTOR: 'COUPON_SELECTOR',
};
