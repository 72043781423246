/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import qs from 'qs';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import SimpleFullscreen from './SimpleFullscreen';
import { filterState, fullScreenState, snackbarState } from 'atoms/atoms';
import { nf } from 'functions';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import { setyyMMdd } from 'utils/date';
import Review from 'components/Review';
import ClipboardJS from 'clipboard';
import useFullScreenClose from './hooks';

const Content = () => {
  const navigate = useNavigate();
  const markerRef = useRef(null);
  const markersRef = useRef([]);
  const prevMarker = useRef(null);
  const mapRef = useRef(null);
  const selectedMarkerRef = useRef(null);
  const swipeRef = useRef(null);
  const fullscreenValue = useRecoilValue(fullScreenState);

  const openSnackbar = useSetRecoilState(snackbarState);
  const propertyList = fullscreenValue.state.data;
  const [cur, setCur] = useState(0);

  const filterValue = useRecoilValue(filterState);

  const { onClickClose } = useFullScreenClose();

  function copy() {
    new ClipboardJS('.btn-copy').on('success', () => {
      openSnackbar({ content: '주소가 복사되었습니다.', open: true });
    });
  }

  const swipeSlide = (index) => {
    setCur(index);
    if (!markersRef.current) return () => {};
    const unavailable = propertyList[index].vacancy === 0;

    if (
      !selectedMarkerRef.current ||
      selectedMarkerRef.current !== markersRef.current[index]
    ) {
      const prevUnavailable = propertyList[cur].vacancy === 0;
      const className = prevUnavailable ? 'case04' : 'case02';
      if (selectedMarkerRef.current) {
        selectedMarkerRef.current.setIcon({
          content: `<div style='width: 6rem;' class='location-case ${className}'><p style='justify-content: center;'>${
            prevUnavailable
              ? '방이 없어요'
              : `${nf(propertyList[cur].sale_price)}원`
          }</p></div>`,
          anchor: new window.naver.maps.Point(70, 70),
        });
        selectedMarkerRef.current.setZIndex(100);
      }
      markersRef.current[index].setIcon({
        content: `<div style='width: 6rem;' class='location-case case01'><p style='justify-content: center;'>${
          unavailable
            ? '방이 없어요'
            : `${nf(propertyList[index].sale_price)}원`
        }</p></div>`,
        anchor: new window.naver.maps.Point(70, 70),
      });
      markersRef.current[index].setZIndex(200);
      selectedMarkerRef.current = markersRef.current[index];
    }
  };

  const markerClickEvent = useCallback(
    (item, i) => {
      const marker = markersRef.current[i];
      window.naver.maps.Event.addListener(marker, 'click', () => {
        const unavailable = item.vacancy === 0;
        setCur(i);

        swipeRef.current.slideTo(i);

        if (
          !selectedMarkerRef.current ||
          selectedMarkerRef.current !== marker
        ) {
          if (selectedMarkerRef.current) {
            const prevunavailable = prevMarker.current?.vacancy === 0;
            const className = prevunavailable ? 'case04' : 'case02';
            const prevPrice = prevMarker.current?.sale_price;

            selectedMarkerRef.current.setIcon({
              content: `<div style='width: 6rem;' class='location-case case02 ${className}'><p style='justify-content: center;'>${
                prevunavailable ? '방이 없어요' : `${nf(prevPrice)}원`
              }</p></div>`,
              anchor: new window.naver.maps.Point(70, 70),
            });
            selectedMarkerRef.current.setZIndex(100);
          }

          marker.setIcon({
            content: `<div style='width: 6rem;' class='location-case case01'><p style='justify-content: center;'>${
              unavailable ? '방이 없어요' : `${nf(item.sale_price)}원`
            }</p></div>`,
            anchor: new window.naver.maps.Point(70, 70),
          });
          markersRef.current.setZIndex(200);
          selectedMarkerRef.current = marker;
        }
      });
      prevMarker.current = item;
    },
    [cur]
  );

  const showMarker = (map, marker) => {
    if (marker.setMap()) return;
    marker.setMap(map);
  };

  const hideMarker = (marker) => {
    if (!marker.setMap()) return;
    marker.setMap(null);
  };

  const updateMarkers = (map, markers) => {
    const mapBounds = map.getBounds();

    markers.forEach((marker, i) => {
      const position = marker.getPosition();

      if (mapBounds.hasLatLng(position)) {
        showMarker(map, marker);
      } else {
        hideMarker(marker);
      }
    });
  };

  useEffect(() => {
    // 지도 생성
    if (!window.naver.maps || !mapRef.current) return;
    const position = new window.naver.maps.LatLng(
      fullscreenValue.state.location.latitude,
      fullscreenValue.state.location.longitude
    );
    const map = new window.naver.maps.Map(mapRef.current, {
      zoom: 14,
      center: position,
    });
    const bounds = map.getBounds();
    const bound = {
      south: bounds.getSW(),
      north: bounds.getNE(),
    };
    const locationSpan = {
      lng: bound.north.lng() - bound.south.lng(),
      lat: bound.north.lat() - bound.south.lat(),
    };

    new window.naver.maps.Marker({
      position,
      map,
      zIndex: 200,
      icon: {
        content: `<img src="https://www.checkinn.kr/icon-property-marker.svg" alt="marker" width="44" height="44" />`,
        anchor: new window.naver.maps.Point(16, 16),
      },
    });
    // property marker 생성
    propertyList.forEach((item, i) => {
      const unavailable = item.vacancy === 0;
      const className = unavailable ? 'case04' : 'case02';

      markerRef.current = new window.naver.maps.Marker({
        index: i,
        position: new window.naver.maps.LatLng(item.lat, item.lng),
        map,
        zIndex: unavailable ? 1 : 100,
        icon: {
          content: `<div style='width: 6rem;' class='location-case ${className}'><p style='justify-content: center;'>${
            unavailable ? '방이 없어요' : `${nf(item.sale_price)}원`
          }</p></div>`,
          anchor: new window.naver.maps.Point(70, 70),
        },
      });
      if (markerRef.current) markersRef.current.push(markerRef.current);
      markerRef.current.onClick = markerClickEvent(item, i);

      window.naver.maps.Event.addListener(map, 'zoom_changed', function () {
        updateMarkers(map, markersRef.current);
      });

      window.naver.maps.Event.addListener(map, 'dragend', function () {
        updateMarkers(map, markersRef.current);
      });
    });
    return () => {
      if (mapRef.current) mapRef.current.destroy();
    };
  }, []);

  // 숙소에 마커 생성
  return (
    <section id="app">
      <div id="address">
        <div className="address">
          <div className="address-map">
            <div ref={mapRef} style={{ height: '100%' }} />
          </div>
          <div className="address-copy">
            <button
              type="button"
              className="btn-copy"
              onClick={copy}
              data-clipboard-text={fullscreenValue.state.location.address}
            >
              <i className="icon-copy"></i>
              <p>{fullscreenValue.state.location.address}</p>
            </button>
          </div>
          <Swiper
            slidesPerGroup={1}
            spaceBetween={10}
            className="category-map-list"
            onSwiper={(swiper) => (swipeRef.current = swiper)}
            onActiveIndexChange={(e) => swipeSlide(e.realIndex)}
            speed={10}
          >
            <div className="swiper-wrapper">
              {propertyList.map((property, i) => {
                return (
                  <SwiperSlide
                    className="slide-cursor"
                    key={`${property.id}index${i}`}
                    onClick={() => {
                      onClickClose({ nav: false });
                      navigate(
                        `/properties/${property.id}/detail?${qs.stringify({
                          from_date: setyyMMdd(filterValue.from_date),
                          to_date: setyyMMdd(filterValue.to_date),
                          capacity: filterValue.capacity,
                        })}`
                      );
                    }}
                  >
                    <div className="box">
                      <div className="image">
                        <img src={property.photos[0].url} alt="숙소 사진" />
                      </div>
                      <div className="cont">
                        <div className="tag">
                          {property.discount_rate > 0 && (
                            <p className="price-tag">
                              {property.discount_rate}% 할인
                            </p>
                          )}
                          {property.top_tags
                            .filter((item) => item.length > 0)
                            .map((tag) => {
                              return <p key={tag}>{tag}</p>;
                            })}
                        </div>
                        <div className="title">{property.name}</div>
                        {property.num_reviews > 0 && (
                          <Review
                            rate={property.rating}
                            reviewNum={property.num_reviews}
                          />
                        )}
                        <div className="price">
                          {property.discount_rate > 0 && (
                            <p className="discount">
                              {nf(property.base_price)}원
                            </p>
                          )}
                          {property.sale_price > 0 ? (
                            <p className="sale">
                              <strong>{nf(property.sale_price)}</strong>
                              <em>원</em>
                            </p>
                          ) : (
                            <p className="unavailble-property">방이 없어요</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

const PropertyMapModal = () => {
  return (
    <SimpleFullscreen title="숙소 위치 및 주변 숙소">
      <Content />
    </SimpleFullscreen>
  );
};

export default PropertyMapModal;
