import React, { useContext, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { isDesktop } from 'react-device-detect';
import styled from 'styled-components';

import { bottomSheetState, popupState } from 'atoms/atoms';
import { POPUP_TYPE } from 'constants/popup';
import { UserContext } from 'context/auth';

import { nf } from 'functions';

import { Between, Col, Row } from 'styles/Layout';
import ChannelButton from 'Channel/ChannelButton';
import useChannelService from 'Channel/useChannelService';

const ButtomBtn = ({
  nights,
  discount,
  basePrice,
  salePrice,
  booking,
  vacancy,
}) => {
  const openPopup = useSetRecoilState(popupState);
  const setBottomSheet = useSetRecoilState(bottomSheetState);
  const { isLogin } = useContext(UserContext);
  const ref = useRef();

  useChannelService();

  const openDutchPopup = () => {
    if (isDesktop) {
      return alert('모바일 앱 전용 기능입니다. 앱을 이용해 주세요!');
    }
    openPopup({ type: 'DUTCH_FOR_MOBILE', open: true });
  };

  const submit = async () => {
    if (!isLogin)
      return openPopup({ type: POPUP_TYPE.RESERVATION_NO_MEMBER, open: true });
    else {
      setBottomSheet({
        open: true,
        type: 'BOOKING',
        state: {
          ...booking,
        },
      });
    }
  };

  return (
    <>
      <ChannelButton bottom={discount > 0 ? '160' : '140'} />
      <Container ref={ref}>
        <Col gap="20">
          {vacancy ? (
            <Content>
              <Nights>총 {nights}박</Nights>

              <Col gap="2">
                {discount > 0 && <BasePrice>{nf(basePrice)}원</BasePrice>}
                <Row gap="6">
                  {discount > 0 && <Discount>{discount}%</Discount>}
                  <SalePrice>{nf(salePrice)}원</SalePrice>
                </Row>
              </Col>
            </Content>
          ) : (
            <></>
          )}

          {vacancy ? (
            <Between gap="20">
              <Button onClick={openDutchPopup}>1/N 예약하기</Button>
              <Button onClick={submit}>예약하기</Button>
            </Between>
          ) : (
            <Between gap="20">
              <Button disabled onClick={openDutchPopup}>
                예약 마감된 객실
              </Button>
              <Button disabled onClick={submit}>
                예약 마감된 객실
              </Button>
            </Between>
          )}
        </Col>
      </Container>
    </>
  );
};

const Container = styled.section`
  position: sticky;
  bottom: 0%;
  padding: 15px 20px 20px;
  background: #ffffff;
  border-top: 1px solid #f1f1f1;
`;

const Button = styled.button`
  width: 100%;

  background: #0080ff;
  border-radius: 10px 10px 0px 10px;
  font-weight: bold;
  padding: 14px;
  font-size: 1rem;
  color: #ffffff;

  &:disabled {
    background: var(--theme-button-disabled);
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Nights = styled.span`
  font-weight: bold;
  font-size: var(--theme-text-l);
  color: var(--theme-text-primary);
`;

export const BasePrice = styled.span`
  font-size: var(--theme-text-m);
  text-decoration-line: line-through;
  color: var(--theme-text-teritary);
  text-align: right;
`;

const SalePrice = styled.span`
  font-weight: bold;
  font-size: var(--theme-text-xl);
  color: var(--theme-text-primary);
`;

const Discount = styled(SalePrice)`
  color: var(--theme-red-secondary);
`;

export default ButtomBtn;
