import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';

import useUseable from 'Coupon/hooks/useUseable';
import useFullScreenClose from './hooks';
import useIssue from 'Coupon/hooks/useRegisterCoupon';

import { fullScreenState, paymentState } from 'atoms/atoms';

import SimpleFullscreen from './SimpleFullscreen';

import { setKoDate } from 'utils/date';
import { getAmount } from 'utils/utils';

import { Text, Text12 } from 'property/booking/index.style';
import { Col } from 'styles/Layout';
import { nf } from 'functions';

const CouponSelectorModal = () => {
  const {
    state: { salePrice },
  } = useRecoilValue(fullScreenState);

  const { onClickClose } = useFullScreenClose();
  const issueCoupon = useIssue();

  const { data: useableCoupons } = useUseable({
    price: salePrice,
  });

  const [payment, setPayment] = useRecoilState(paymentState);

  const [selected, setSelected] = useState(
    useableCoupons?.find((item) => item.id === payment.selectedCouponId)
  );
  const [couponValue, setCouponValue] = useState('');

  const setCouponType = (type, item) => {
    return type === 'percent'
      ? `최대 ${item.discount_rate}% 할인`
      : `${nf(item.discount_amount)}원 할인`;
  };

  const onApplyCoupon = () => {
    if (selected) {
      setPayment((cur) => ({
        ...cur,
        selectedCouponId: selected.id,
        couponDiscountAmount: selected.discount_amount,
      }));
    } else {
      setPayment((cur) => ({
        ...cur,
        selectedCouponId: null,
        couponDiscountAmount: 0,
      }));
    }

    onClickClose({ nav: true });
  };

  const onSelecte = (item) => {
    if (item.id === selected?.id) {
      setSelected(null);
    } else {
      setSelected(item);
    }
  };

  const onIssueCoupon = ({ couponId }) => {
    issueCoupon.mutate(
      { code: couponId },
      {
        onSettled: () => {
          setCouponValue('');
        },
      }
    );
  };

  return (
    <SimpleFullscreen title="쿠폰">
      <ColContainer>
        <Flex>
          <Col gap="30">
            <CouponIssueLabel>
              <CouponIssueBar
                type="text"
                placeholder="쿠폰코드를 입력해 주세요."
                value={couponValue}
                onChange={(e) => setCouponValue(e.target.value)}
              />
              <CouponIssueButton
                onClick={() => onIssueCoupon({ couponId: couponValue })}
              >
                등록
              </CouponIssueButton>
            </CouponIssueLabel>
            <CouponList>
              {useableCoupons?.map((item) => (
                <CouponItem
                  key={item.id}
                  onClick={() => onSelecte(item)}
                  selected={item.id === selected?.id}
                >
                  <Text12 bold color="var(--theme-blue-primary)">
                    {setCouponType(item.discount_type, item)}
                  </Text12>
                  <Text>{item.name}</Text>
                  <Col gap="2">
                    <Text12 color="var(--theme-text-secondary)">
                      {setKoDate(item.effective_from)} ~{' '}
                      {setKoDate(item.effective_to)}{' '}
                    </Text12>

                    <Text12 color="var(--theme-text-secondary)">
                      {getAmount(item.min_order_amount, item.max_discount)}
                    </Text12>
                  </Col>
                </CouponItem>
              ))}
            </CouponList>
          </Col>
        </Flex>

        <ApplyButton onClick={onApplyCoupon}>적용하기</ApplyButton>
      </ColContainer>
    </SimpleFullscreen>
  );
};

const ColContainer = styled(Col)`
  padding: 30px 20px;
  min-height: 100%;
`;

const CouponIssueLabel = styled.label`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const Flex = styled.div`
  flex: 1;
`;

const CouponIssueBar = styled.input`
  width: 100%;
  border-radius: 10px 10px 0 10px;
  background: #f8f8f8;
  border: 1px solid #f1f1f1;
  padding: 14px;
  font-size: 14px;
`;

const CouponIssueButton = styled.button`
  border-radius: 10px 10px 0 10px;
  background: var(--theme-blue-primary);
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  padding: 14px 24px;
  white-space: nowrap;
`;

const CouponList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  height: 100%;
`;

const CouponItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 10px 12px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  ${(props) =>
    props.selected &&
    css`
      border: 1px solid var(--theme-blue-primary);
      box-shadow: none;
    `}
`;

const ApplyButton = styled.button`
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  background: var(--theme-blue-primary);
  padding: 12px;
  border-radius: 10px 10px 0 10px;
`;

export default CouponSelectorModal;
