import { useQueryClient } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import SimplePopup from './SimplePopup';

import { UserContext } from 'context/auth';
import { MESSAGE } from 'utils/message';

const LogoutPopup = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { userDispatch, user } = useContext(UserContext);
  const onClick = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    userDispatch({ type: 'LOGOUT' });
    queryClient.invalidateQueries(['home', 'title']);
    navigate('/');
  };
  return (
    <SimplePopup
      title={MESSAGE.LOGOUT.TITLE}
      content={
        <span>
          {user.nick_name}님은 현재{' '}
          <span style={{ color: '#0080FF' }}>{user.provider}</span>에 로그인되어
          있으며, 이메일은{' '}
          <span style={{ color: '#0080FF' }}>{user.email}</span>입니다.
        </span>
      }
      cancel="취소"
      confirm="로그아웃"
      onClick={onClick}
    />
  );
};

export default LogoutPopup;
